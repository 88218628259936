import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
}

const EmailAuthButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={onClick}
      className="w-full bg-red-500 text-white p-2 rounded-xl text-lg"
    >
      {t('createAccountPage.continueWithEmail')}
    </button>
  );
};

export default EmailAuthButton;