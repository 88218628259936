import { ReactComponent as Twitter } from '../assets/icons/twitter-icon.svg';
import { ReactComponent as Instagram } from '../assets/icons/instagram-icon.svg';
import { ReactComponent as Linkedin } from '../assets/icons/linkedin-icon.svg';
import { useTranslation } from "react-i18next";


const ContactHero: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="py-12 px-4 md:px-8">
      <div className="flex flex-col md:flex-row items-center max-w-7xl mx-auto">
        <div className="md:w-2/3 mb-4 md:mb-0">
          <img
            src="/assets/img/contact-cellphone.png"
            alt="Contact Information"
            className="w-full max-w-xs mx-auto"
          />
        </div>
        <div className="md:w-4/5 md:pl-2">
          <h2 className="text-3xl uppercase font-bold mb-4 text-textBrand dark:text-dark-textBrand">{t("landingPage.contactHero.title")}</h2>
          <h3 className="text-2xl font-bold mb-6">{t("landingPage.contactHero.subtitle")}</h3>
          <p className="text-cTextSecondary mb-8 dark:text-dark-cText">{t("landingPage.contactHero.description")}</p>
          <div className="bg-bgBrand text-textOnBrand dark:bg-dark-bgBrand dark:text-dark-textOnBrand p-6 rounded-md">
            <h4 className="text-xl font-bold mb-4">{t("landingPage.contactHero.titleBanner")}</h4>
            <div className='flex justify-between items-center'>
              <div className="space-y-2">
                <p>{t("landingPage.contactHero.phone")}: +57 305525412</p>
                <p>{t("landingPage.contactHero.email")}: info@xervix.com</p>
              </div>
              <div className="flex self-end gap-2">
                <a href="#" className="hover:opacity-80 transition-opacity">
                <Twitter className='size-7 fill-white' />
                </a>
                <a href="#" className="hover:opacity-80 transition-opacity">
                <Instagram className='size-7 fill-white'/>
                </a>
                <a href="#" className="hover:opacity-80 transition-opacity">
                <Linkedin className='size-7 fill-white'/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactHero;
