import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
}

const CompanyButton: React.FC<Props> = ({ onClick }) => {
    const { t } = useTranslation();
    return (
    <button
      onClick={onClick}
      className="w-full bg-bgBrand text-white p-4 rounded-xl flex items-center justify-center gap-2"
    >
      <img
        src="/assets/img/company-icon.png"
        alt="Company Icon"
        className="w-7 h-7"
      />
      <span>{t("createAccountPage.titleCompany")}</span>
    </button>
  );
};

export default CompanyButton;