
import React from "react";

const CardCategoriesEmpresas: React.FC = () => {
  return (
    <div className=" col-span-8 bg-white shadow rounded-lg p-6">
{/* History */}


{/* Category */}
<div className="mt-6">
  <h2 className="text-sm font-semibold">Categoría Carpintería</h2>
  <div className="grid grid-cols-6 gap-2 mt-4">
    {Array(18)
      .fill(null)
      .map((_, index) => (
        <button
          key={index}
          className="bg-red-100 text-red-500 text-sm font-medium py-2 rounded-md"
        >
          Rejected
        </button>
      ))}
  </div>
</div>

{/* Add Service */}
<div className="mt-6 flex items-center">
  <select className="border rounded-md p-2 ">
    <option>Elige el nuevo servicio a ingresar</option>
    <option>Carpintería</option>
    <option>Salud</option>   
  </select>
  <button className="bg-gray-200 text-gray-700 px-4 py-2 ml-4 rounded-md">
    Añadir Servicio
  </button>
</div>
</div>
  )};



  export default CardCategoriesEmpresas;
