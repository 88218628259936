import React, { useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useAuthStore from '../store/useAuthStore';
import routesConfig from '../config/routes.config';

const VerifyCodePage: React.FC = () => {
  const { t } = useTranslation();
  const { sendCodeTo } = useParams<{ sendCodeTo: string }>(); 
  const [code, setCode] = useState(['', '', '', '', '', '']); // Updated for 6 digits
  const { verifyCode, resendVerificationCode, isLoading } = useAuthStore();
  const navigate = useNavigate();

  // Create refs for each input to control focus
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  // Handle changes in the verification code inputs
  const handleCodeChange = (value: string, index: number) => {
    const newCode = [...code];
    newCode[index] = value;

    if (value !== '' && index < 5) {
      inputRefs.current[index + 1]?.focus(); // Move focus to the next input
    }
    
    setCode(newCode);
  };

  // Verify the entered code
  const handleVerifyCode = async () => {
    const codeString = code.join('');

    try {
      await verifyCode(codeString, sendCodeTo || '');
      navigate(routesConfig.categoryOnboarding) 
    } catch (error) {
      console.error('Error verifying code:', error);
    }
  };

  // Resend the verification code
  const handleResendCode = async () => {
    try {
      await resendVerificationCode();
      alert(t('verifyCodePage.codeResent')); 
    } catch (error) {
      console.error('Error resending code:', error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-white">
      <div className="bg-white p-6 shadow-lg rounded-lg w-full max-w-md">
        <h1 className="text-2xl font-bold text-center mb-4">{t('verifyCodePage.title')}</h1>
        <p className="text-center mb-6">
          {t('verifyCodePage.instructions', { email: sendCodeTo })} 
        </p>
        <div className="flex justify-center gap-2 mb-4">
          {code.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={digit}
              onChange={(e) => handleCodeChange(e.target.value, index)}
              className="w-12 h-12 border border-gray-300 text-center text-xl rounded-md"
              ref={(el) => (inputRefs.current[index] = el)} // Store input ref
              onKeyDown={(e) => {
                if (e.key === 'Backspace' && digit === '' && index > 0) {
                  inputRefs.current[index - 1]?.focus(); // Move focus to the previous input if backspace
                }
              }}
            />
          ))}
        </div>
        <button
          onClick={handleVerifyCode}
          disabled={isLoading || code.some((digit) => digit === '')}
          className={`w-full py-3 rounded-lg text-lg mb-4 ${
            isLoading || code.some((digit) => digit === '')
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'bg-red-500 text-white'
          }`}
        >
          {isLoading ? t('verifyCodePage.verifying') : t('verifyCodePage.verifyButton')}
        </button>
        <p className="text-center text-sm">
          {t('verifyCodePage.didNotReceive')}
          <button
            onClick={handleResendCode}
            disabled={isLoading}
            className="text-blue-500 ml-1"
          >
            {isLoading ? t('verifyCodePage.sending') : t('verifyCodePage.resendLink')}
          </button>
        </p>
      </div>
    </div>
  );
};

export default VerifyCodePage;
