import React from "react";
import CardAdminXervix from "./components/card_admin";

import Sidebar from "./components/sidebar_admin";
import TableOpinionesAprove from "./components/table_opiniones_aprove";
import { Link } from "react-router-dom";
import TableOpinionesHistory from "./components/table_opiniones_history";
import RatingSummary from "./components/card_opniones";

const AdminPageOpinionesHistorial: React.FC = () => {
    const ratingsData = [
        { rating: 5, count: 273 },
        { rating: 4, count: 221 },
        { rating: 3, count: 178 },
        { rating: 2, count: 150 },
        { rating: 1, count: 98 },
      ];
    
      const totalReviews = ratingsData.reduce((acc, curr) => acc + curr.count, 0);
    
  return (
    <div className="flex p-6 bg-gray-50 min-h-screen">
    <Sidebar />

<div className="ml-10 w-full">   

    <div className="flex justify-end text-sm mt-10">    
      <Link to="/admin/opiniones" className="px-4"><span>Aprobación de comentarios</span></Link>
      <Link to="/admin/opiniones/historial" className="px-4"><span>Historial</span></Link>     
    </div>

    <div className="flex justify-start">

    <div className="w-96">
        <RatingSummary data={ratingsData} totalReviews={totalReviews} />
        </div> 
    </div>       
    
     <TableOpinionesHistory />
   </div>

 </div>   
  );
};

export default AdminPageOpinionesHistorial;