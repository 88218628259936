import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useServiceStore from '../store/useServiceStore';
import { BaseLayout } from '../components/layout/BaseLayout';
import routesConfig from '../config/routes.config';

const CategoryServicesPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const category = location.state?.category;
  const { fetchServices, filterServicesByCategory, isLoading, error } = useServiceStore();
  const services = filterServicesByCategory(category.name);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const handleClick = (serviceId: string) => {
    navigate(routesConfig.serviceForm.replace(':id', serviceId));
  };

  if (!category) {
    return <div className="text-center py-4">No category selected</div>;
  }

  if (isLoading) {
    return <div className="text-center py-4">Loading services...</div>;
  }

  if (error) {
    return <div className="text-center py-4 text-red-500">Error: {error}</div>;
  }

  return (
    <BaseLayout>
      <div className="container mx-auto px-4 py-8 xl:max-w-[90%] 2xl:max-w-[1600px] min-h-screen">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
          <div className="lg:col-span-2">
            <h1 className="text-4xl font-bold text-cText dark:text-dark-cText mb-6">
              Servicios de {category.name} que marcan la diferencia
            </h1>
            <img
              src={category.imageUrl}
              alt={category.name}
              className="w-full h-64 object-cover rounded-lg mb-8"
            />
            {/* Formulario de "No Encuentras lo que necesitas" */}
            <div className="bg-cBackground dark:bg-dark-cBackground p-6 rounded-lg shadow-md mb-12">
              <h2 className="text-2xl font-bold text-cText dark:text-dark-cText mb-4">
                ¿No encuentras lo que necesitas?
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="col-span-1">
                  <p className="text-cTextSecondary dark:text-dark-cTextSecondary">
                    No te preocupes, cuéntanos qué servicio estás buscando. Encontraremos empresas que te puedan ayudar y actualizaremos este servicio a nuestro portafolio.
                  </p>
                  <input
                    type="text"
                    placeholder="¿Qué servicio necesitas?"
                    className="mt-4 w-full px-4 py-2 border border-cBorder dark:border-dark-cBorder rounded-lg bg-cBackground dark:bg-dark-cBackgroundSecondary text-cText dark:text-dark-cText"
                  />
                </div>
                <div className="col-span-1">
                  <p className="text-cTextSecondary dark:text-dark-cTextSecondary mt-4 md:mt-0">
                    Si quieres que te avisemos cuando tengamos este servicio en nuestro portafolio, ingresa tu correo a continuación:
                  </p>
                  <div className="flex mt-4">
                    <input
                      type="email"
                      placeholder="Ingresa tu correo electrónico"
                      className="flex-1 px-4 py-2 border border-cBorder dark:border-dark-cBorder rounded-lg bg-cBackground dark:bg-dark-cBackgroundSecondary text-cText dark:text-dark-cText"
                    />
                    <button className="bg-bgBrand dark:bg-dark-bgBrand text-textOnBrand px-6 py-2 rounded-lg ml-2">
                      ENVIAR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:col-span-1">
            {/* Servicios Disponibles */}
            <div className="mb-12">
              <h2 className="text-3xl font-bold text-cText dark:text-dark-cText mb-6">
                Todos los servicios en un solo lugar!
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {services.map((service: any) => (
                  <div
                    key={service.serviceId}
                    className="bg-cBackground dark:bg-dark-cBackground p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow"
                    onClick={() => handleClick(service.serviceId)}
                  >
                    <h3 className="text-lg font-semibold text-cText dark:text-dark-cText mb-2">
                      {service.name}
                    </h3>
                    <p className="text-cTextSecondary dark:text-dark-cTextSecondary">{service.provider}</p>
                    <p className="text-cText dark:text-dark-cText font-bold text-lg">{service.price}</p>
                    <button className="mt-4 bg-bgBrand dark:bg-dark-bgBrand text-textOnBrand px-4 py-2 rounded-lg">
                      Enviar Solicitud
                    </button>
                  </div>
                ))}
              </div>
            </div>
            {/* Buscar Servicio */}
            <div className="bg-cBackground dark:bg-dark-cBackground p-6 rounded-lg shadow-md">
              <h2 className="text-2xl font-bold text-cText dark:text-dark-cText mb-4">
                Si no estás seguro, busca tu servicio directamente
              </h2>
              <input
                type="text"
                placeholder="¿Qué servicio necesitas?"
                className="w-full px-4 py-2 border border-cBorder dark:border-dark-cBorder rounded-lg bg-cBackground dark:bg-dark-cBackgroundSecondary text-cText dark:text-dark-cText"
              />
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default CategoryServicesPage;
