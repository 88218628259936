export const getMockCategories = async () => {
  return {
    data: [
      {
        categoryId: "c1a2b3",
        imageUrl: "https://images.pexels.com/photos/6593514/pexels-photo-6593514.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Tatuajes",
        subcategories: [
          {
            subcategoryId: "s1a2b3",
            name: "Diseño de Tatuajes",
            services: [
              {
                serviceId: "sv1a2b3",
                name: "Tatuaje a color",
                subcategoryId: "s1a2b3",
                subcategoryName: "Diseño de Tatuajes",
              },
              {
                serviceId: "sv2a2b3",
                name: "Tatuaje en blanco y negro",
                subcategoryId: "s1a2b3",
                subcategoryName: "Diseño de Tatuajes",
              },
            ],
          },
          {
            subcategoryId: "s2a2b3",
            name: "Tatuaje a domicilio",
            services: [
              {
                serviceId: "sv1a2b4",
                name: "Tatuaje a color",
                subcategoryId: "s2a2b3",
                subcategoryName: "Tatuaje a domicilio",
              },
              {
                serviceId: "sv2a2b4",
                name: "Tatuaje en blanco y negro",
                subcategoryId: "s2a2b3",
                subcategoryName: "Tatuaje a domicilio",
              },
            ],
          },
          {
            subcategoryId: "s3a2b3",
            name: "Eliminación de Tatuajes",
            services: [
              {
                serviceId: "sv1a2b5",
                name: "Láser",
                subcategoryId: "s3a2b3",
                subcategoryName: "Eliminación de Tatuajes",
              },
              {
                serviceId: "sv2a2b5",
                name: "Crema",
                subcategoryId: "s3a2b3",
                subcategoryName: "Eliminación de Tatuajes",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c2a2b3",
        imageUrl: "https://images.pexels.com/photos/5677366/pexels-photo-5677366.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Arte",
        subcategories: [
          {
            subcategoryId: "s2-1a2b3",
            name: "Pintura",
            services: [
              {
                serviceId: "sv2-1-1a2b3",
                name: "Acuarela",
                subcategoryId: "s2-1a2b3",
                subcategoryName: "Pintura",
              },
              {
                serviceId: "sv2-1-2a2b3",
                name: "Óleo",
                subcategoryId: "s2-1a2b3",
                subcategoryName: "Pintura",
              },
            ],
          },
          {
            subcategoryId: "s2-2a2b3",
            name: "Escultura",
            services: [
              {
                serviceId: "sv2-2-1a2b3",
                name: "Mármol",
                subcategoryId: "s2-2a2b3",
                subcategoryName: "Escultura",
              },
              {
                serviceId: "sv2-2-2a2b3",
                name: "Bronce",
                subcategoryId: "s2-2a2b3",
                subcategoryName: "Escultura",
              },
            ],
          },
          {
            subcategoryId: "s2-3a2b3",
            name: "Dibujo",
            services: [
              {
                serviceId: "sv2-3-1a2b3",
                name: "Lápiz",
                subcategoryId: "s2-3a2b3",
                subcategoryName: "Dibujo",
              },
              {
                serviceId: "sv2-3-2a2b3",
                name: "Carboncillo",
                subcategoryId: "s2-3a2b3",
                subcategoryName: "Dibujo",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c3a2b3",
        imageUrl: "https://images.pexels.com/photos/1249611/pexels-photo-1249611.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Carpintería",
        subcategories: [
          {
            subcategoryId: "s3-1a2b3",
            name: "Muebles",
            services: [
              {
                serviceId: "sv3-1-1a2b3",
                name: "Mesas",
                subcategoryId: "s3-1a2b3",
                subcategoryName: "Muebles",
              },
              {
                serviceId: "sv3-1-2a2b3",
                name: "Sillas",
                subcategoryId: "s3-1a2b3",
                subcategoryName: "Muebles",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c4a2b3",
        imageUrl: "https://images.pexels.com/photos/834892/pexels-photo-834892.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Arquitectura",
        subcategories: [
          {
            subcategoryId: "s4-1a2b3",
            name: "Diseño de Interiores",
            services: [
              {
                serviceId: "sv4-1-1a2b3",
                name: "Planos",
                subcategoryId: "s4-1a2b3",
                subcategoryName: "Diseño de Interiores",
              },
              {
                serviceId: "sv4-1-2a2b3",
                name: "Renderizados",
                subcategoryId: "s4-1a2b3",
                subcategoryName: "Diseño de Interiores",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c5a2b3",
        imageUrl: "https://images.pexels.com/photos/5727885/pexels-photo-5727885.jpeg",
        name: "Desarrollo",
        subcategories: [
          {
            subcategoryId: "s5-1a2b3",
            name: "Desarrollo Web",
            services: [
              {
                serviceId: "sv5-1-1a2b3",
                name: "Frontend",
                subcategoryId: "s5-1a2b3",
                subcategoryName: "Desarrollo Web",
              },
              {
                serviceId: "sv5-1-2a2b3",
                name: "Backend",
                subcategoryId: "s5-1a2b3",
                subcategoryName: "Desarrollo Web",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c6a2b3",
        imageUrl: "https://images.pexels.com/photos/3318215/pexels-photo-3318215.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Mascotas",
        subcategories: [
          {
            subcategoryId: "s6-1a2b3",
            name: "Cuidado de Mascotas",
            services: [
              {
                serviceId: "sv6-1-1a2b3",
                name: "Paseo",
                subcategoryId: "s6-1a2b3",
                subcategoryName: "Cuidado de Mascotas",
              },
              {
                serviceId: "sv6-1-2a2b3",
                name: "Aseo",
                subcategoryId: "s6-1a2b3",
                subcategoryName: "Cuidado de Mascotas",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c7a2b3",
        imageUrl: "https://images.pexels.com/photos/7688336/pexels-photo-7688336.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Marketing",
        subcategories: [
          {
            subcategoryId: "s7-1a2b3",
            name: "Publicidad",
            services: [
              {
                serviceId: "sv7-1-1a2b3",
                name: "SEO",
                subcategoryId: "s7-1a2b3",
                subcategoryName: "Publicidad",
              },
              {
                serviceId: "sv7-1-2a2b3",
                name: "SEM",
                subcategoryId: "s7-1a2b3",
                subcategoryName: "Publicidad",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c8a2b3",
        imageUrl: "https://images.pexels.com/photos/7235900/pexels-photo-7235900.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Turismo",
        subcategories: [
          {
            subcategoryId: "s8-1a2b3",
            name: "Guías Turísticos",
            services: [
              {
                serviceId: "sv8-1-1a2b3",
                name: "Tours",
                subcategoryId: "s8-1a2b3",
                subcategoryName: "Guías Turísticos",
              },
              {
                serviceId: "sv8-1-2a2b3",
                name: "Excursiones",
                subcategoryId: "s8-1a2b3",
                subcategoryName: "Guías Turísticos",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c9a2b3",
        imageUrl: "https://images.pexels.com/photos/3971985/pexels-photo-3971985.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Música",
        subcategories: [
          {
            subcategoryId: "s9-1a2b3",
            name: "Clases de Música",
            services: [
              {
                serviceId: "sv9-1-1a2b3",
                name: "Guitarra",
                subcategoryId: "s9-1a2b3",
                subcategoryName: "Clases de Música",
              },
              {
                serviceId: "sv9-1-2a2b3",
                name: "Piano",
                subcategoryId: "s9-1a2b3",
                subcategoryName: "Clases de Música",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c10a2b3",
        imageUrl: "https://images.pexels.com/photos/7641415/pexels-photo-7641415.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Cuidadores",
        subcategories: [
          {
            subcategoryId: "s10-1a2b3",
            name: "Cuidado de Ancianos",
            services: [
              {
                serviceId: "sv10-1-1a2b3",
                name: "Enfermería",
                subcategoryId: "s10-1a2b3",
                subcategoryName: "Cuidado de Ancianos",
              },
              {
                serviceId: "sv10-1-2a2b3",
                name: "Acompañamiento",
                subcategoryId: "s10-1a2b3",
                subcategoryName: "Cuidado de Ancianos",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c11a2b3",
        imageUrl: "https://images.pexels.com/photos/414029/pexels-photo-414029.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Fitness",
        subcategories: [
          {
            subcategoryId: "s11-1a2b3",
            name: "Entrenamiento Personal",
            services: [
              {
                serviceId: "sv11-1-1a2b3",
                name: "Cardio",
                subcategoryId: "s11-1a2b3",
                subcategoryName: "Entrenamiento Personal",
              },
              {
                serviceId: "sv11-1-2a2b3",
                name: "Pesas",
                subcategoryId: "s11-1a2b3",
                subcategoryName: "Entrenamiento Personal",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c12a2b3",
        imageUrl: "https://images.pexels.com/photos/1884582/pexels-photo-1884582.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Belleza",
        subcategories: [
          {
            subcategoryId: "s12-1a2b3",
            name: "Salón de Belleza",
            services: [
              {
                serviceId: "sv12-1-1a2b3",
                name: "Corte de Pelo",
                subcategoryId: "s12-1a2b3",
                subcategoryName: "Salón de Belleza",
              },
              {
                serviceId: "sv12-1-2a2b3",
                name: "Manicura",
                subcategoryId: "s12-1a2b3",
                subcategoryName: "Salón de Belleza",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c13a2b3",
        imageUrl: "https://images.pexels.com/photos/4489743/pexels-photo-4489743.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Automotriz",
        subcategories: [
          {
            subcategoryId: "s13-1a2b3",
            name: "Mecánica",
            services: [
              {
                serviceId: "sv13-1-1a2b3",
                name: "Cambio de Aceite",
                subcategoryId: "s13-1a2b3",
                subcategoryName: "Mecánica",
              },
              {
                serviceId: "sv13-1-2a2b3",
                name: "Reparación de Frenos",
                subcategoryId: "s13-1a2b3",
                subcategoryName: "Mecánica",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c14a2b3",
        imageUrl: "https://images.pexels.com/photos/7217837/pexels-photo-7217837.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Hogar",
        subcategories: [
          {
            subcategoryId: "s14-1a2b3",
            name: "Limpieza",
            services: [
              {
                serviceId: "sv14-1-1a2b3",
                name: "Limpieza General",
                subcategoryId: "s14-1a2b3",
                subcategoryName: "Limpieza",
              },
              {
                serviceId: "sv14-1-2a2b3",
                name: "Limpieza de Ventanas",
                subcategoryId: "s14-1a2b3",
                subcategoryName: "Limpieza",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c15a2b3",
        imageUrl: "https://images.pexels.com/photos/6502823/pexels-photo-6502823.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Idiomas",
        subcategories: [
          {
            subcategoryId: "s15-1a2b3",
            name: "Clases de Idiomas",
            services: [
              {
                serviceId: "sv15-1-1a2b3",
                name: "Inglés",
                subcategoryId: "s15-1a2b3",
                subcategoryName: "Clases de Idiomas",
              },
              {
                serviceId: "sv15-1-2a2b3",
                name: "Francés",
                subcategoryId: "s15-1a2b3",
                subcategoryName: "Clases de Idiomas",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c16a2b3",
        imageUrl: "https://images.pexels.com/photos/12919428/pexels-photo-12919428.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Organización de Eventos",
        subcategories: [
          {
            subcategoryId: "s16-1a2b3",
            name: "Planificación de Eventos",
            services: [
              {
                serviceId: "sv16-1-1a2b3",
                name: "Bodas",
                subcategoryId: "s16-1a2b3",
                subcategoryName: "Planificación de Eventos",
              },
              {
                serviceId: "sv16-1-2a2b3",
                name: "Cumpleaños",
                subcategoryId: "s16-1a2b3",
                subcategoryName: "Planificación de Eventos",
              },
            ],
          },
        ],
      },
      {
        categoryId: "c17a2b3",
        imageUrl: "https://images.pexels.com/photos/40568/medical-appointment-doctor-healthcare-40568.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        name: "Salud",
        subcategories: [
          {
            subcategoryId: "s17-1a2b3",
            name: "Consultas Médicas",
            services: [
              {
                serviceId: "sv17-1-1a2b3",
                name: "General",
                subcategoryId: "s17-1a2b3",
                subcategoryName: "Consultas Médicas",
              },
              {
                serviceId: "sv17-1-2a2b3",
                name: "Especialista",
                subcategoryId: "s17-1a2b3",
                subcategoryName: "Consultas Médicas",
              },
            ],
          },
        ],
      },
    ],
  };
};
