import { GoogleLogin } from '@react-oauth/google';

interface Props {
  onSuccess: (credentialResponse: any) => void;
}

export const GoogleAuthButton = ({ onSuccess }: Props) => {
  return (
    <div className="w-full">
      <GoogleLogin
        onSuccess={onSuccess}
        onError={() => console.log('Login Failed')}
        useOneTap
        theme="outline"
        size="large"
        text="continue_with"
        shape="rectangular"
        width="100%"
      />
    </div>
  );
};