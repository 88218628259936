import React, { useState } from "react";
import Modal from "../components/Modal";

import CategoriesSlider from "../components/CategoriesSlider";
import ServicesHero from "../components/ServicesHero";
import MarketplaceHero from "../components/MarketplaceHero";
import TopServicesHero from "../components/TopServicesHero";
import JoinCommunity from "../components/JoinCommunityHero";
import AppDownloadHero from "../components/AppDownloadHero";
import ContactHero from "../components/ContactHero";
import ServiceSearchField from "../components/ServiceSearchComponent";
import { BaseLayout } from "../components/layout/BaseLayout";
import { useNavigate } from "react-router-dom";
import ViewAllButton from "../components/ViewAllButton";

const LandingPage = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  
  return (
    <BaseLayout>
      <div>
        <Modal isOpen={isModalOpen} onClose={handleCloseModal} title="En Reparación">
          <p>Esta funcionalidad está en reparación. Por favor, inténtelo más tarde.</p>
        </Modal>
        <div className="flex flex-col gap-6">
          <ServiceSearchField />
          <ViewAllButton />
          <CategoriesSlider />
          <ServicesHero onButtonClick={handleOpenModal} />
          <TopServicesHero onButtonClick={handleOpenModal} />
          <MarketplaceHero onButtonClick={handleOpenModal} />
          <JoinCommunity onJoinClick={handleOpenModal} />
          <AppDownloadHero onButtonClick={handleOpenModal} />
          <ContactHero />
        </div>
      </div>
    </BaseLayout>
  );
};

export default LandingPage;
