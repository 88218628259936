import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import authService from '../../services/auth.service';
import { NextStepGuardRouteString } from '../../guards/NextStepGuard';
import { jwtDecode } from 'jwt-decode';
import { DecodedToken } from '../../types/auth.types';
import FacebookAuthButton from '../../components/auth/FacebookAuthButton';
import { GoogleAuthButton } from '../../components/auth/GoogleAuthButton';
import EmailAuthButton from '../../components/auth/EmailAuthButton';
import CompanyButton from '../../components/auth/CompanyButton';

type RegistrationSection = {
  title: string;
  description: string;
  subText?: string;
  showRegistrationOptions: boolean;
  actionButton?: React.ReactNode;
};

const CreateAccountPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  
  const isProfessionalRegistration = location.pathname.includes('company');
  const userType = isProfessionalRegistration ? 'PROFESSIONAL' : 'USER';

  const handleGoogleSuccess = async (credentialResponse: any) => {
    try {
      const registerResponse = await authService.googleRegister(credentialResponse.credential, userType);

      localStorage.setItem('accessToken', registerResponse?.accessToken);
      localStorage.setItem('refreshToken', registerResponse?.refreshToken);
      localStorage.setItem('nextStep', registerResponse?.nextStep?.toString());
      localStorage.setItem('authResponse', JSON.stringify(registerResponse));

      const redirectTo = userType === 'PROFESSIONAL' ? NextStepGuardRouteString() : '/landing-page';
      navigate(redirectTo);
    } catch (error) {
      console.error('Error during Google signup:', error);
    }
  };

  const handleEmailSignup = () => {
    navigate(userType === 'PROFESSIONAL' ? '/signup' : '/signup-customer-page');
  };

  const handleCompanySignup = () => {
    navigate('/create-account-page/company');
  };

  const renderRegistrationOptions = () => (
    <div className="flex flex-col gap-4 w-full">
      <EmailAuthButton onClick={handleEmailSignup} />
      <GoogleAuthButton onSuccess={handleGoogleSuccess} />
      <FacebookAuthButton />
    </div>
  );

  const sections: RegistrationSection[] = isProfessionalRegistration ? 
    [
      {
        title: t('createAccountPage.titleCompany'),
        description: t('createAccountPage.descriptionCompany'),
        subText: t('createAccountPage.subTextCompany'),
        showRegistrationOptions: true
      }
    ] : [
      {
        title: t('createAccountPage.titleUser'),
        description: t('createAccountPage.descriptionUser'),
        showRegistrationOptions: true
      },
      {
        title: t('createAccountPage.titleCompany'),
        description: t('createAccountPage.descriptionCompany'),
        subText: t('createAccountPage.subTextCompany'),
        showRegistrationOptions: false,
        actionButton: <CompanyButton onClick={handleCompanySignup} />
      }
    ];

  return (
    <div className="flex min-h-screen">
      <div
        className="hidden md:flex md:w-2/3 bg-cover bg-center"
        style={{ backgroundImage: 'url(/assets/img/login-side-img.png)' }}
      />
      <div className="flex flex-col justify-center md:w-1/3 w-full p-24 gap-10">
        {sections.map((section, index) => (
          <div key={index} className="flex flex-col items-center">
            <h1 className="text-2xl font-bold mb-6">{section.title}</h1>
            <p className="text-gray-500 text-center mb-8">{section.description}</p>
            {section.subText && (
              <span className='text-[#f97068] text-sm font-normal text-left self-start my-8 mb-16'>
                {section.subText}
              </span>
            )}
            {section.showRegistrationOptions && renderRegistrationOptions()}
            {section.actionButton}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreateAccountPage;
