import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useOnboardingStore from '../store/useOnboardingStore'; // Importa el store de Zustand para manejar el onboarding
import { NextStepGuardRouteString } from '../guards/NextStepGuard';

const SubscriptionOnboardingPage: React.FC = () => {
  const { t } = useTranslation(); // Usamos useTranslation para manejar las traducciones
  const navigate = useNavigate();
  const { updateReferralEmail, isLoading } = useOnboardingStore(); // Obtenemos el método para actualizar el email de referido
  const [referalEmail, setReferalEmail] = useState<string>(''); // Estado para el email del referido
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para manejar el botón de carga

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReferalEmail(e.target.value);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      // Llamamos a la función del store que actualiza el email de referido
      const response = await updateReferralEmail(referalEmail);
      const redirectTo = NextStepGuardRouteString(); // Ajusta la redirección según tu configuración de rutas
      navigate(redirectTo); // Navegamos al siguiente paso
      // alert(t('subscription.onboardingCompleted')); // Mensaje traducido de éxito
    } catch (error:any) {
      // alert(`${t('subscription.error')}: ${error?.message}`); // Mensaje traducido de error
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-white p-6">
     
      {/* Contenido */}
      <div className="flex flex-col items-center mt-8">
        {/* Título */}
        <h1 className="text-4xl font-bold text-red-500">XERVIX</h1>
        <p className="text-2xl text-black mt-4">{t('subscription.title')}</p>

        {/* Texto introductorio */}
        <p className="text-lg text-gray-700 mt-8 text-center">
          {t('subscription.introText')}
        </p>

        {/* Campo de correo de referido */}
        <div className="w-full max-w-md mt-10">
          <label className="text-lg font-semibold block mb-2">{t('subscription.referredQuestion')}</label>
          <input
            type="email"
            placeholder={t('subscription.emailPlaceholder')}
            value={referalEmail}
            onChange={handleEmailChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-red-500"
          />
        </div>

        {/* Texto adicional */}
        <p className="text-sm text-gray-600 mt-4 text-center">
          {t('subscription.referralBonus')}
        </p>

        {/* Botón de suscripción */}
        <div className="mt-10">
          {isSubmitting ? (
            <div className="flex justify-center">
              <div className="loader"></div> {/* Indicador de carga */}
            </div>
          ) : (
            <button
              onClick={handleSubmit}
              className="bg-red-500 text-white px-8 py-3 rounded-full hover:bg-red-600 transition duration-300"
              disabled={isLoading}
            >
              {isLoading ? t('subscription.loading') : t('subscription.startFreeTrial')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionOnboardingPage;
