import React from 'react';
import { useTranslation } from 'react-i18next';

const ViewTermsAndConditionsPage: React.FC = () => {
  const { t } = useTranslation(); // Hook para las traducciones

  return (
    <div className="min-h-screen flex flex-col bg-white p-6">
      {/* AppBar */}
      <div className="flex justify-start">
        <h1 className="text-4xl font-bold text-red-500">{t('terms.title')}</h1>
      </div>

      {/* Contenido */}
      <div className="flex flex-col items-center mt-8">
        <p className="text-sm text-gray-500 mt-2">{t('terms.lastUpdate')}</p>

        {/* Texto de los términos */}
        <div className="mt-8 w-full max-w-3xl bg-gray-100 p-6 rounded-lg shadow-md overflow-y-auto" style={{ height: '500px' }}>
          <p className="text-lg font-bold">{t('terms.termsTitle')}</p>
          <p className="text-sm text-gray-700 mt-2 whitespace-pre-wrap">
            {t('terms.termsText')}
          </p>
        </div>

        {/* Enlaces para descargar PDF o eliminar la cuenta
        <div className="mt-8 flex justify-around w-full max-w-3xl">
          <a
            href="/path-to-terms.pdf" // Asegúrate de tener el archivo PDF disponible en tu servidor
            className="text-blue-500 underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('terms.downloadPdf')}
          </a>

          <a
            href="/delete-account" // Ajusta el enlace según sea necesario
            className="text-blue-500 underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('terms.deleteAccount')}
          </a>
        </div> */}

        {/* Enlaces a las tiendas de Apple y Google
        <div className="mt-8 flex justify-around w-full max-w-3xl">
          <a
            href="https://apps.apple.com/your-app-url"
            className="text-blue-500 underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('terms.appleStore')}
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=your.app.id"
            className="text-blue-500 underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('terms.googlePlay')}
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default ViewTermsAndConditionsPage;
