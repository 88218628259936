import React from "react";
import Sidebar from "./components/sidebar_admin";
import { Link } from "react-router-dom";
import TableReelsHistory from "./components/table_reels_resume";
import MobileFrame from "./components/screen_reel";


const AdminPageReelsHistorial: React.FC = () => {     
    
  return (
    <div className="flex p-6 bg-gray-50 min-h-screen">
    <Sidebar />

<div className="ml-10 w-full">   

    <div className="flex justify-end text-sm mt-2">    
    <Link to="/admin/marketplace" className="px-4"><span>Reels Activos</span></Link>
    <Link to="/admin/marketplace/resume" className="px-4"><span>Aprobación de Reels</span></Link>    
    </div>   

    <div className="flex mt-16 justify-between mr-20">
        
     <TableReelsHistory />

     <MobileFrame />

   
        </div>    
    
   </div>

 </div>   
  );
};

export default AdminPageReelsHistorial;