import React from "react";

interface CardProps {
  title: string;
  value: string | number;
  icon: React.ReactNode; // El ícono puede ser un componente React o cualquier nodo.
  description: string;
  trendColor: string; // Clase de TailwindCSS para el color de la tendencia.
}

const CardAdminXervix: React.FC<CardProps> = ({ title, value, icon, description, trendColor }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-4 flex flex-col items-start space-y-2">   
        <h3 className="text-gray-600 font-medium text-xs">{title}</h3>  
      <div className="text-lg font-bold">{value}</div>
      <div className={`text-xs font-medium ${trendColor}`}>{description}</div>
    </div>
  );
};

export default CardAdminXervix