import React from "react";

// Definimos la estructura de cada fila
interface TableRow {
  id: string;
  usuarios: number;
  empresas: number;
  ventas: string;
  servicios: number;
  status: string;
}

const TableResumenDashboard: React.FC = () => {
  const data: TableRow[] = [
    { id: "ENERO", usuarios: 1080, empresas: 2100, ventas: "1'560.000", servicios: 15, status: "Completed" },
    { id: "FEBRERO", usuarios: 1080, empresas: 2100, ventas: "1'560.000", servicios: 15, status: "Completed" },
    { id: "MARZO", usuarios: 1080, empresas: 2100, ventas: "1'560.000", servicios: 15, status: "Completed" },
    { id: "ABRIL", usuarios: 1080, empresas: 2100, ventas: "1'560.000", servicios: 15, status: "Completed" },
    { id: "MAYO", usuarios: 1080, empresas: 2100, ventas: "1'560.000", servicios: 15, status: "Completed" },
    { id: "JUNIO", usuarios: 1080, empresas: 2100, ventas: "1'560.000", servicios: 15, status: "Completed" },
    { id: "JULIO", usuarios: 1080, empresas: 2100, ventas: "1'560.000", servicios: 15, status: "Completed" },
    { id: "AGOSTO", usuarios: 1080, empresas: 2100, ventas: "1'560.000", servicios: 15, status: "Completed" },
    { id: "SEPTIEMBRE", usuarios: 1080, empresas: 2100, ventas: "1'560.000", servicios: 15, status: "Completed" },
    // Agrega más filas aquí...
  ];

  return (
    <div className="overflow-x-auto bg-white shadow-lg rounded-lg p-4">
        <h2 className="text-center pb-6 font-bold">Resumen general de Xervix</h2>
      <table className="table-auto w-full border-collapse border border-gray-200">
        <thead className=" text-gray-700">
          <tr>
            <th className="px-4 py-3 border border-gray-200 text-left text-sm">Mes</th>
            <th className="px-4 py-3 border border-gray-200 text-sm">Usuarios</th>
            <th className="px-4 py-3 border border-gray-200 text-sm">Empresas</th>
            <th className="px-4 py-3 border border-gray-200 text-sm">Gnancias</th>
            <th className="px-4 py-3 border border-gray-200 text-sm">Servicios</th>
            <th className="px-4 py-3 border border-gray-200 text-sm">Estado</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="text-gray-700 text-center" >
              <td className="px-4 py-3 border border-gray-200 text-left text-xs">{row.id}</td>
              <td className="px-4 py-3 border border-gray-200 text-xs">{row.usuarios}</td>
              <td className="px-4 py-3 border border-gray-200 text-xs">{row.empresas}</td>
              <td className="px-4 py-3 border border-gray-200 text-xs">{row.ventas}</td>
              <td className="px-4 py-3 border border-gray-200 text-xs">{row.servicios}</td>
              <td className="px-4 py-3 border border-gray-200 text-xs">
                <span className="text-green-600 font-semibold">{row.status}</span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableResumenDashboard;