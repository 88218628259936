import React from 'react';

interface ModalProps {
  title: string;
  message: string;
  buttonText: string;
  imageUrl: string;
  onClose: () => void;
  className?: string;
}

const Modal: React.FC<ModalProps> = ({ title, message, buttonText, imageUrl, onClose, className }) => {
  return (
    <div className={`fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center ${className ?? ''}`}>
      <div className="bg-white rounded-lg p-8 w-96 shadow-lg text-center">
        <img
          src={imageUrl} // Imagen pasada por props
          alt="Modal"
          className="mx-auto mb-4"
          style={{ width: '100px', height: '100px' }}
        />
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        <p className="mb-6">{message}</p>
        <button
          onClick={onClose}
          className="bg-red-500 text-white px-4 py-2 rounded-lg"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default Modal;
