import React from "react";

interface CardProps {
  title: string;
  headers: string[];
  data: (string | number)[][];
}

const StatCard: React.FC<CardProps> = ({ title, headers, data }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 w-full max-w-md">
      <h2 className="text-lg font-bold mb-4">{title}</h2>
      <table className="w-full text-left text-sm border-collapse">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index} className="font-semibold text-gray-700 py-2 border-b">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} className="py-2 border-b">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CardEstadisticas: React.FC = () => {
  const cardsData = [
    {
      title: "Top 3 empresas con más servicios ejecutados",
      headers: ["Empresa", "Número de servicios", "Total ganado"],
      data: [
        ["Madera y madera", 325, "45'653.000"],
        ["Muebles y más", 300, "40'000.000"],
        ["Construcciones XYZ", 280, "38'500.000"],
      ],
    },
    {
      title: "Top 3 categorías más solicitadas",
      headers: ["Categoría", "Número de servicios", "Total facturado"],
      data: [
        ["Corte de cabello", 325, "45'653.000"],
        ["Pintura de casas", 300, "40'000.000"],
        ["Reparaciones", 280, "38'500.000"],
      ],
    },
    {
      title: "Usuarios con más servicios",
      headers: ["Usuario", "Número de servicios", "Total facturado"],
      data: [
        ["Juan Pérez", 325, "45'653.000"],
        ["Ana Gómez", 300, "40'000.000"],
        ["Carlos Ruiz", 280, "38'500.000"],
      ],
    },
    {
      title: "Top 3 servicios más populares",
      headers: ["Servicio", "Número de servicios", "Total facturado"],
      data: [
        ["Corte de cabello", 325, "45'653.000"],
        ["Mantenimiento", 300, "40'000.000"],
        ["Limpieza profunda", 280, "38'500.000"],
      ],
    },
    {
      title: "Empresas mejor calificadas",
      headers: ["Empresa", "Número de servicios", "Calificación"],
      data: [
        ["Madera y madera", 325, 5.0],
        ["Muebles y más", 300, 4.9],
        ["Construcciones XYZ", 280, 4.8],
      ],
    },
  ];

  return (
    <div className="p-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {cardsData.map((card, index) => (
        <StatCard key={index} title={card.title} headers={card.headers} data={card.data} />
      ))}
    </div>
  );
};

export default CardEstadisticas;
