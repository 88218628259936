export const API_ROUTES = {
    ACCOUNT: {
        LOGIN: '/Account/login',
        REGISTER: '/Account/register',
        RESET_PASSWORD: '/Account/reset-password',
        VERIFY_CODE: '/Account/verify-code',
        RESEND_VERIFICATION_CODE_EMAIL: '/Account/resend-verification-code-email',
        GOOGLE_LOGIN: '/Account/google-login',
        GOOGLE_REGISTER: '/Account/google-register',
        REFRESH_TOKEN: '/Account/refresh-token',
      },
      CATEGORIES: {
        FETCH_CATEGORIES: '/Categories',
        FETCH_CATEGORY: (categoryId: string) => `/Categories/${categoryId}`,
        SEARCH_CATEGORIES: '/Categories/search',
      },
      PROFESSIONAL: {
        UPSERT_BUSINESS_INFO: '/Professionals/upsert-business-info',
        UPDATE_REFERAL_EMAIL: '/Professionals/update-referal-email',
        ACCEPT_TERMS_AND_CONDITIONS: '/Professionals/accept-terms-and-conditions',
        COMPLETE_ONBOARDING: '/Professionals/complete-onboarding',
        SUBMIT_CATEGORIES: '/Professionals/bulk-categories',
        SUBMIT_SERVICES: '/Professionals/bulk-services',
        UPDATE_LOCATION: '/Professionals/update-location',
        UPLOAD_DOCUMENTS: '/ProfessionalDocuments/upload-documents',
        FETCH_AVAILABLE_SERVICES: '/Professionals/available-services',
      },
      SERVICES: {
        FETCH_SERVICES: '/Services',
        FETCH_SERVICE: (serviceId: string) => `/Services/${serviceId}`,
      },
      BUSINESS_TYPES: '/BusinessTypes',
};