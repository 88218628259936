import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useOnboardingStore from '../store/useOnboardingStore'; // Store de Zustand para manejar el proceso de onboarding
import { NextStepGuardRouteString } from '../guards/NextStepGuard';

const TermsAndConditionsPage: React.FC = () => {
  const { t } = useTranslation(); // Usamos el hook de traducción
  const navigate = useNavigate();
  const { acceptTermsAndConditions, isLoading } = useOnboardingStore(); // Store para aceptar términos y condiciones
  const [isChecked, setIsChecked] = useState(false); // Estado para manejar el checkbox
  const [isSubmitting, setIsSubmitting] = useState(false); // Estado para manejar el botón de carga

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    try {
      // Llamamos a la función del store para aceptar los términos
      const response = await acceptTermsAndConditions(true);
      const redirectTo = NextStepGuardRouteString(); // Ajusta la redirección según tu configuración de rutas
      navigate(redirectTo); // Navegamos al siguiente paso
    } catch (error:any) {
      alert(`${t('terms.error')}: ${error?.message}`); // Mensaje traducido de error
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-white p-6">
      
      <div className="flex flex-col items-center mt-8">
        {/* Título */}
        <h1 className="text-4xl font-bold text-red-500">{t('terms.title')}</h1>
        <p className="text-sm text-gray-500 mt-2">{t('terms.lastUpdate')}</p>

        {/* Texto de los términos */}
        <div className="mt-8 w-full max-w-3xl bg-gray-100 p-6 rounded-lg shadow-md overflow-y-auto" style={{ height: '400px' }}>
          <p className="text-lg font-bold">{t('terms.termsTitle')}</p>
          <p className="text-sm text-gray-700 mt-2 whitespace-pre-wrap">
            {t('terms.termsText')}
          </p>
        </div>

        {/* Aceptación de los términos */}
        <div className="mt-6 flex items-center">
          <input
            type="checkbox"
            id="acceptTerms"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className="mr-2"
          />
          <label htmlFor="acceptTerms" className="text-gray-700">
            {t('terms.accept')}
          </label>
        </div>

        {/* Botón para aceptar */}
        <div className="mt-6">
          {isSubmitting ? (
            <div className="flex justify-center">
              <div className="loader"></div> {/* Indicador de carga */}
            </div>
          ) : (
            <button
              onClick={handleSubmit}
              className={`bg-red-500 text-white px-8 py-3 rounded-full ${!isChecked ? 'opacity-50' : 'hover:bg-red-600'} transition duration-300`}
              disabled={!isChecked || isLoading}
            >
              {isLoading ? t('terms.loading') : t('terms.acceptButton')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
