import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { useNavigate, useParams } from 'react-router-dom';
import useOnboardingStore from '../store/useOnboardingStore';
import { NextStepGuardRouteString } from '../guards/NextStepGuard';

interface DocumentType {
  key: string;
  name: string;
  file: File | null;
  isPending: boolean;
}

const naturalPersonType = '30f08549-8bc1-4a2f-9c74-a8b648d63d05';
const legalEntityType = '8a0cac57-b85b-4c80-8bdb-a8174267e079';

const DocumentsOnboardingPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { documentType } = useParams(); 
  const { uploadIndividualDocument, skipUpload, isLoading } = useOnboardingStore();
  const [selectedImage, setSelectedImage] = useState<File | null>(null); // Para el logo
  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const maxFileSizeMB = 20;

  // Inicializar la lista de documentos según el tipo de persona
  useEffect(() => {
    if (documentType === naturalPersonType) {
      setDocuments([
        { key: 'IdentityDocument', name: t('documents.documentTypes.identification'), file: null, isPending: true },
        { key: 'BankingCertificate', name: t('documents.documentTypes.bankCertificate'), file: null, isPending: true },
        { key: 'RUT', name: t('documents.documentTypes.RUT'), file: null, isPending: true },
      ]);
    } else if (documentType === legalEntityType) {
      setDocuments([
        { key: 'IdentityDocument', name: t('documents.documentTypes.identification'), file: null, isPending: true },
        { key: 'ChamberCertificate', name: t('documents.documentTypes.chamberCertificate'), file: null, isPending: true },
        { key: 'BankingCertificate', name: t('documents.documentTypes.bankCertificate'), file: null, isPending: true },
        { key: 'RUT', name: t('documents.documentTypes.RUT'), file: null, isPending: true },
      ]);
    }
  }, [documentType, t]);

  // Para el logo (image/jpeg, image/png)
  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const fileSizeMB = file.size / (1024 * 1024);
      if (fileSizeMB > maxFileSizeMB) {
        alert(t('documents.fileTooLarge', { size: maxFileSizeMB }));
      } else {
        setSelectedImage(file);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'image/jpeg': [], 'image/png': [] },
    maxSize: maxFileSizeMB * 1024 * 1024,
  });

  // Manejar archivos PDF de los documentos
  const handleFileUpload = (index: number, file: File | null) => {
    const updatedDocuments = [...documents];
    updatedDocuments[index].file = file;
    updatedDocuments[index].isPending = !file;
    setDocuments(updatedDocuments);
  };

  const resetFile = (index: number) => {
    const updatedDocuments = [...documents];
    updatedDocuments[index].file = null;
    updatedDocuments[index].isPending = true;
    setDocuments(updatedDocuments);
  };

  // Subir documentos y el logo individualmente
  const handleSubmitDocuments = async (skipStep: boolean) => {
    const files = documents.filter((doc) => doc.file !== null).map((doc) => doc.file) as File[];
    
    try {
      if (!skipStep) {
        if (selectedImage) {
          // Subir el logo como documento de tipo 'CompanyLogo'
          await uploadIndividualDocument(selectedImage, 0); // 0 para CompanyLogo
        }
        for (let i = 0; i < files.length; i++) {
          await uploadIndividualDocument(files[i], i + 1); // Subir cada documento con su tipo
        }
      }

      if (skipStep) {
        await skipUpload(); // Enviar el "skip" si el usuario decide continuar sin subir documentos
      }

      const redirectTo = NextStepGuardRouteString();
      console.log('Redirigiendo a:', redirectTo);
      navigate(redirectTo);

    } catch (error) {
      console.error('Error al subir documentos o hacer skip:', error);
    }
  };

  const handleContinue = () => {
    const allDocumentsUploaded = documents.every((doc) => doc.file !== null);
    const logoUploaded = selectedImage !== null;

    // Si todo está seleccionado, skipStep es false
    if (allDocumentsUploaded && logoUploaded) {
      handleSubmitDocuments(false); // skipStep = false
    } else if (!allDocumentsUploaded || !logoUploaded) {
      // Si se ha seleccionado algo pero falta algo, skipStep es false pero mostramos el modal
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const continueWithoutDocuments = async () => {
    const hasSelectedAnyDocumentOrLogo = selectedImage !== null || documents.some((doc) => doc.file !== null);

    if (hasSelectedAnyDocumentOrLogo) {
      await handleSubmitDocuments(false); // Si seleccionó al menos un archivo o logo, skipStep = false
    } else {
      await handleSubmitDocuments(true); // Si no seleccionó nada, skipStep = true
    }

    closeModal();
  };

  return (
    <div className="min-h-screen flex flex-col items-center bg-white py-8">
      <h1 className="text-4xl font-bold text-red-500">XERVIX</h1>
      <p className="mt-2 text-lg text-gray-500">Autentica tu empresa</p>

      <div className="mt-8">
        <div {...getRootProps()} className="w-32 h-32 rounded-full border-2 border-gray-300 flex items-center justify-center">
          <input {...getInputProps()} />
          {selectedImage ? (
            <img src={URL.createObjectURL(selectedImage)} alt="Logo Preview" className="w-full h-full object-cover rounded-full" />
          ) : (
            <p className="text-gray-400">{t('documents.uploadLogo')}</p>
          )}
        </div>
      </div>

      <div className="mt-8 w-full max-w-xl">
        <h2 className="text-lg font-semibold mb-4">{t('documents.title')} (solo PDF)</h2>
        {documents.map((doc, index) => (
          <div key={doc.key} className="flex items-center justify-between mb-4 p-4 bg-gray-100 rounded-lg">
            <div className="flex items-center">
              <i className="fas fa-upload text-gray-500 mr-2"></i>
              <span>{doc.name}</span>
            </div>
            <div>
              {doc.file ? (
                <div className="flex items-center">
                  <span className="text-gray-500 mr-4">{doc.file.name}</span>
                  <button
                    className="text-red-500 underline"
                    onClick={() => resetFile(index)}
                  >
                    {t('documents.remove')}
                  </button>
                </div>
              ) : (
                <label className="text-blue-500 cursor-pointer">
                  <input
                    type="file"
                    className="hidden"
                    accept="application/pdf"
                    onChange={(e) => handleFileUpload(index, e.target.files ? e.target.files[0] : null)}
                  />
                  {t('documents.upload')}
                </label>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8">
        <button
          className="bg-red-500 text-white px-12 py-3 rounded-full"
          onClick={handleContinue}
          disabled={isLoading}
        >
          {isLoading ? t('documents.loading') : t('documents.continue')}
        </button>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center w-96">
            <h2 className="text-lg font-semibold mb-4">{t('documents.modalTitle')}</h2>
            <p className="mb-4">{t('documents.modalMessage')}</p>

            <ul className="text-left">
              <li className="flex items-center mb-2">
                {selectedImage ? (
                  <span className="text-green-500 flex items-center">
                    <i className="fas fa-check-circle mr-2"></i> {t('documents.readyToUpload')} {t('documents.uploadLogo')}
                  </span>
                ) : (
                  <span className="text-red-500 flex items-center">
                    <i className="fas fa-times-circle mr-2"></i> {t('documents.notReady')} {t('documents.uploadLogo')}
                  </span>
                )}
              </li>
              {documents.map((doc) => (
                <li key={doc.key} className="flex items-center mb-2">
                  {doc.file ? (
                    <span className="text-green-500 flex items-center">
                      <i className="fas fa-check-circle mr-2"></i> {t('documents.readyToUpload')} {doc.name}
                    </span>
                  ) : (
                    <span className="text-red-500 flex items-center">
                      <i className="fas fa-times-circle mr-2"></i> {t('documents.notReady')} {doc.name}
                    </span>
                  )}
                </li>
              ))}
            </ul>

            <div className="flex justify-around mt-4">
              <button className="bg-gray-300 text-gray-700 px-6 py-2 rounded-full" onClick={closeModal}>
                {t('documents.cancel')}
              </button>
              <button className="bg-red-500 text-white px-6 py-2 rounded-full" onClick={continueWithoutDocuments}>
                {t('documents.continueWithout')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentsOnboardingPage;
