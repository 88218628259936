import React, { useState } from "react";

interface DataRow {
  solicitud: number;
  fecha: string;
  referencia: string;
  banco: string;
  usuario: string;
  email: string;
  montoRecibido: number;
  comisionPasarela: number;
  comisionXervix: number;
}

const initialData: DataRow[] = [
  {
    solicitud: 1256321,
    fecha: "2025-02-24",
    referencia: "65136545",
    banco: "Bancolombia",
    usuario: "Camilo Gomez",
    email: "juancamilo@gmail.com",
    montoRecibido: 125000,
    comisionPasarela: 350,
    comisionXervix: 320,
  },
  {
    solicitud: 1256322,
    fecha: "2025-02-23",
    referencia: "65136546",
    banco: "Davivienda",
    usuario: "Laura Pérez",
    email: "lauraperez@gmail.com",
    montoRecibido: 140000,
    comisionPasarela: 400,
    comisionXervix: 350,
  },
  {
    solicitud: 1256323,
    fecha: "2025-02-22",
    referencia: "65136547",
    banco: "BBVA",
    usuario: "Carlos Ruiz",
    email: "carlosruiz@gmail.com",
    montoRecibido: 110000,
    comisionPasarela: 300,
    comisionXervix: 280,
  },
  {
    solicitud: 1256324,
    fecha: "2025-02-25",
    referencia: "65136548",
    banco: "Banco de Bogotá",
    usuario: "Ana Martínez",
    email: "anamartinez@gmail.com",
    montoRecibido: 130000,
    comisionPasarela: 360,
    comisionXervix: 330,
  },
];

const TableGananciasSuscripciones: React.FC = () => {
  const [data, setData] = useState<DataRow[]>(initialData);
  const [sortColumn, setSortColumn] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const handleSort = (column: keyof DataRow) => {
    const direction = sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    const sortedData = [...data].sort((a, b) => {
      if (a[column] < b[column]) return direction === "asc" ? -1 : 1;
      if (a[column] > b[column]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setData(sortedData);
    setSortColumn(column);
    setSortDirection(direction);
  };

  return (
    <div className="p-4 overflow-y-auto max-h-96">
      <h1 className="text-lg font-bold mb-4 text-center py-4">Ingresos por Suscripciones</h1>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr>
            <th className="px-4 py-2 border cursor-pointer" onClick={() => handleSort("fecha")}>
              Fecha
            </th>
            <th className="px-4 py-2 border cursor-pointer" onClick={() => handleSort("banco")}>
              Banco
            </th>
            <th className="px-4 py-2 border cursor-pointer" onClick={() => handleSort("montoRecibido")}>
              Monto Transferido
            </th>
            <th className="px-4 py-2 border cursor-pointer" onClick={() => handleSort("comisionPasarela")}>
              Comisión Pasarela
            </th>
            <th className="px-4 py-2 border cursor-pointer" onClick={() => handleSort("comisionXervix")}>
              Comisión Xervix
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.solicitud} className="text-center">
              <td className="px-4 py-2 border">{row.fecha}</td>
              <td className="px-4 py-2 border">{row.banco}</td>
              <td className="px-4 py-2 border">{row.montoRecibido.toLocaleString()}</td>
              <td className="px-4 py-2 border">{row.comisionPasarela.toLocaleString()}</td>
              <td className="px-4 py-2 border">{row.comisionXervix.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableGananciasSuscripciones;
