import React from "react";

type ProfileFormProps = {
  data: {
    legalName: string;
    desiredName: string;
    email: string;
    phone: string;
    emergencyContact: string;
    address: string;
    country: string;
    city: string;
    businessId: string;
  };
  onEdit: (field: string, value: string) => void;
};

const ProfileFormEmpresas: React.FC<ProfileFormProps> = ({ data, onEdit }) => {
  const fields = [
    { label: "Nombre Legal", value: data.legalName, editable: false },
    { label: "Nombre deseado", value: data.desiredName, editable: true, field: "desiredName" },
    { label: "Correo electrónico", value: data.email, editable: false },
    { label: "Teléfono", value: data.phone, editable: true, field: "phone" },
    { label: "Contacto de emergencia", value: data.emergencyContact, editable: true, field: "emergencyContact" },
    { label: "Dirección", value: data.address, editable: true, field: "address" },
    { label: "País", value: data.country, editable: true, field: "country" },
    { label: "Ciudad", value: data.city, editable: true, field: "city" },
    { label: "Identificación empresa", value: data.businessId, editable: true, field: "businessId" },
  ];

  return (
    <div className="w-full bg-white p-6 shadow-md rounded-md">
      <h2 className="text-sm font-semibold text-gray-800 text-center mb-4">Información de la empresa</h2>
      {fields.map(({ label, value, editable, field }) => (  
        <div>          
           <div key={label} className="flex items-center justify-between py-2 w-full">          
          <div className="w-full">
            <p className="text-xs text-gray-500 mb-2">{label}</p>
            <p className="text-sm font-medium mb-1">{value}</p>            
          </div>          
          {editable && (
            <button
              className="text-red-500 text-sm font-medium"
              onClick={() => {
                const newValue = prompt(`Editar ${label}`, value);
                if (newValue) onEdit(field!, newValue);
              }}
            >
              Editar
            </button>
          )}
        </div>
        <hr className="h-px my-1 bg-gray-200 border-0 dark:bg-gray-700 w-full"></hr>
           </div>       
      ))}
      <div className="mt-4">
        <button
          className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
          onClick={() => alert("Cambiar contraseña")}
        >
          Cambiar contraseña
        </button>
      </div>
    </div>
  );
};

export default ProfileFormEmpresas;
