import React from "react";

const MobileFrame: React.FC = () => {
  return (
    <div className="w-[320px] h-[800px] ">


      {/* Marco del celular */}
      <div className="relative w-[320px] h-[640px] bg-black rounded-[40px] border-[14px] border-black shadow-lg">     
      

        {/* Contenido principal */}
        <div className="absolute inset-0 bg-gray-200 rounded-[26px] overflow-hidden">
          {/* Imagen principal */}
          <div className="relative w-full h-full bg-white z-0">
            <img
              src="https://images.pexels.com/photos/18444356/pexels-photo-18444356/free-photo-of-woman-tattooing-in-black-and-white.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" // Cambia esto por la ruta de tu imagen
              alt="Reels"
              className="object-cover w-full h-full"
            />
            <div className='absolute top-2 lef-0 right-0 text-white text-xs w-full'> 
                <div className= 'w-full flex justify-between px-6'>
                <h1 className="text-xs font-semibold">9:41</h1>
                <h1 className="text-xs font-semibold">TIGO 4G</h1>
                </div>

            </div>
              
            {/* Texto sobre la imagen */}
            <div className="absolute bottom-14 left-4 right-4 text-white text-sm">
              <h1 className="text-lg font-semibold">Buenos recuerdos</h1>
              <p>
                Las noches son más felices con la mojiganga y buenos amigos y
                cerveza
              </p>
              <p className="mt-1 text-xs">#Musica #Medellin #Rock</p>
            </div>
          </div>
        </div>
        

      </div>  {/* termina marco de celular */}

      
        {/* Botones */}
        <div className="relativa mt-4 flex justify-around py-4 bg-gray-50">
          <button className="px-6 py-2 bg-red-500 text-white rounded-md font-semibold hover:bg-red-600">
            APROBAR
          </button>
          <button className="px-6 py-2 bg-gray-300 text-black rounded-md font-semibold hover:bg-gray-400">
            ELIMINAR
          </button>
        </div>


         {/* termina pantalla*/}
    </div> 
  );
};

export default MobileFrame;
