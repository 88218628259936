import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthStore from '../store/useAuthStore';

interface RoleGuardProps {
  allowedRoles: string[];
  children: JSX.Element;
}

const RoleGuard: React.FC<RoleGuardProps> = ({ allowedRoles, children }) => {
  const { role, isAuthenticated } = useAuthStore();

  // Redirige a login si el usuario no está autenticado
  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // Redirige si el usuario no tiene el rol adecuado
  if (!allowedRoles.includes(role || '')) {
    return <Navigate to="/not-authorized" replace />;
  }

  return children;
};

export default RoleGuard;
