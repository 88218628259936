import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-100 dark:bg-gray-800 py-8">
      <div className="container mx-auto px-4">
        <p className="text-center text-gray-600 dark:text-gray-400">© 2024 Xervix. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;