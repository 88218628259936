import React from "react";

type ProfileProps = {
  profile: {
    image: string;
    name: string;
    followers: number;
    following: number;
    likes: number;
    story: string;
  };
  onEditStory: (newStory: string) => void;
};

const ProfileCard: React.FC<ProfileProps> = ({ profile, onEditStory }) => {
  const handleEdit = () => {
    const newStory = prompt("Editar Historia", profile.story);
    if (newStory) {
      onEditStory(newStory);
    }
  };

  return (
    <div className="max-w-md mx-auto bg-white p-6 shadow-md rounded-md text-center h-full">
      {/* Imagen y Nombre */}
      <div className="flex flex-col items-center">
        <img
          src={profile.image}
          alt={profile.name}
          className="w-24 h-24 rounded-full object-cover"
        />
        <h2 className="mt-4 text-lg font-semibold text-gray-800 mb-2">
          {profile.name}
        </h2>
      </div>

      {/* Estadísticas */}
      <div className="flex justify-around mt-4 text-gray-600 mb-8">
        <div>
          <p className="text-2xl font-bold text-red-500">{profile.followers}</p>
          <p className="text-xs">Seguidores</p>
        </div>
        <div>
          <p className="text-2xl font-bold text-red-500">{profile.following}</p>
          <p className="text-xs">Siguiendo</p>
        </div>
        <div>
          <p className="text-2xl font-bold text-red-500">{profile.likes}</p>
          <p className="text-xs">Likes</p>
        </div>
      </div>

      {/* Historia */}
      <div className="mt-6 text-left">
        <div className="flex justify-between items-center">
          <p className="text-sm font-semibold text-gray-800 mb-2">
            Historia {profile.name}
          </p>
          <button
            className="text-red-500 text-xs leading-5"
            onClick={handleEdit}
          >
            Editar
          </button>
        </div>
        <p className="mt-2 text-gray-600 text-xs bg-gray-100 p-4 rounded-md leading-6">
          {profile.story}
        </p>
      </div>
    </div>
  );
};

export default ProfileCard;
