import React from 'react';
import Sidebar from './components/sidebar_admin';
import { Link } from 'react-router-dom';
import TableGanancias from './components/table_pagos_ganancias';
import TableGananciasSuscripciones from './components/table_ganancias_suscripcion';
import TableDevoluciones from './components/table_devoluciones';
import TablePendientes from './components/table_pagospendientes';

const AdminPagePagosPendientes: React.FC = () => {
  

 

  return (

    <div className="flex p-6 min-h-screen"> 
    
    <Sidebar />
    
     <div className="p-8  w-full">
      
     <div className="flex justify-end text-sm mt-2">    
      <Link to="/admin/pagos" className="px-4"><span>Resumen financiero</span></Link>
      <Link to="/admin/pagos/ganancias" className="px-4"><span>Ganancias</span></Link>
      <Link to="/admin/pagos/pendientes" className="px-4"><span>Pendientes</span></Link>
     
    </div>
      <h1 className="text-lg font-bold text-center mb-4">PAGOS XERVIX</h1>
      
      {/* Tabla de resumen */}
      
      {/* Resumen financiero */}
      <div className="mt-8 grid grid-cols-2 gap-4">
        <div className="px-4 py-8 bg-blue-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Saldo en cuenta Bancaria</h3>
          <p className="text-xl font-bold">8688422</p>
        </div>
        <div className="px-4 py-8 bg-purple-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Ganancias totales netas</h3>
          <p className="text-xl font-bold">42424242</p>
        </div>       
      </div>

      <TableDevoluciones/>

      <div className="mt-8 grid grid-cols-2 gap-4">
        <div className="px-4 py-8 bg-orange-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Saldo en cuenta Bancaria</h3>
          <p className="text-xl font-bold">8688422</p>
        </div>
        <div className="px-4 py-8 bg-gray-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Ganancias totales netas</h3>
          <p className="text-xl font-bold">42424242</p>
        </div>       
      </div>

      <TablePendientes/>

    </div>
    </div>




   
  );
};

export default AdminPagePagosPendientes;
