import React from 'react';
import {GoogleAuthButton} from "../../components/auth/GoogleAuthButton";
import authService from '../../services/auth.service';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { NextStepGuardRouteString } from '../../guards/NextStepGuard';
import routesConfig from '../../config/routes.config';
import FacebookAuthButton from '../../components/auth/FacebookAuthButton';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';




const Login: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email(t('loginPage.email.validEmail')).required(t('loginPage.email.required')),
    password: Yup.string().required(t('loginPage.password.required')),
  });

  const handleFacebookLoginSuccess = async (response: any) => {
    console.log('Facebook login success:', response);
  };

  const handleFacebookLoginFailure = (error: any) => {
    console.error('Error al iniciar sesión con Facebook:', error);
  };

  const handleGoogleSuccess = async (credentialResponse: any) => {
    try {
      const loginResponse = await authService.googleLogin(credentialResponse.credential);
      
      localStorage.setItem('accessToken', loginResponse?.accessToken);
      localStorage.setItem('refreshToken', loginResponse?.refreshToken);
      localStorage.setItem('nextStep', loginResponse?.nextStep?.toString());
      localStorage.setItem('authResponse', JSON.stringify(loginResponse));

      const redirectTo = loginResponse.userType === 'PROFESSIONAL' ? '/professional-dashboard' : '/';
      navigate(redirectTo);
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  const handleSubmit = async (values: { email: string; password: string }, { setSubmitting, setErrors }: any) => {
    try {
      const response = await authService.login(values.email, values.password);
      console.log('Login successful:', response);

      localStorage.setItem('accessToken', response.accessToken);
      localStorage.setItem('refreshToken', response.refreshToken);
      localStorage.setItem('nextStep', response.nextStep?.toString());
      localStorage.setItem('authResponse', JSON.stringify(response));

      const redirectTo = NextStepGuardRouteString();
      navigate(redirectTo);
    } catch (err: any) {
      console.error('Login failed:', err);
      setErrors({ email: 'Las credenciales son incorrectas o el usuario no está autorizado.' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="flex min-h-screen">
      <div
        className="hidden md:flex md:w-2/3 bg-cover bg-center"
        style={{ backgroundImage: 'url(/assets/img/login-side-img.png)' }}
      />
      <div className="flex flex-col items-center justify-center md:w-1/3 w-full p-8">
        <h1 className="text-2xl font-bold mb-6">{t('loginPage.welcome')}</h1>
        <div className="flex justify-end space-x-4 mb-4">
          <button onClick={() => i18n.changeLanguage('es')} className="text-sm text-gray-500 hover:underline">
            {t('loginPage.spanish')}
          </button>
          <button onClick={() => i18n.changeLanguage('en')} className="text-sm text-gray-500 hover:underline">
            {t('loginPage.english')}
          </button>
        </div>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={LoginSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="w-full max-w-sm">
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {t('loginPage.email.label')}
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-500"
                  placeholder={t('loginPage.email.placeholder') || ''}
                />
                <ErrorMessage name="email" component="div" className="text-sm text-red-600" />
              </div>
              <div className="mb-6">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  {t('loginPage.password.label')}
                </label>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring focus:border-blue-500"
                  placeholder={t('loginPage.password.placeholder') || ''}
                />
                <ErrorMessage name="password" component="div" className="text-sm text-red-600" />
                <div className="text-right mt-2">
                  <a href="#" className="text-sm text-blue-500 hover:underline">
                    {t('loginPage.forgotPassword')}
                  </a>
                </div>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full py-3 ${isSubmitting ? 'bg-gray-400' : 'bg-red-500'} text-white font-bold rounded-full hover:bg-red-600 transition-colors`}
              >
                {isSubmitting ? t('loading') : t('loginPage.loginButton')}
              </button>
            </Form>
          )}
        </Formik>
        <p className="mt-6 text-sm text-gray-600">
          {t('loginPage.noAccount')}{' '}
          <a href={routesConfig.createAccount} className="text-blue-500 hover:underline">
            {t('loginPage.createAccount')}
          </a>
        </p>
        <div className="my-4 flex items-center justify-center w-full">
          <span className="text-sm text-gray-500">{t('loginPage.orLoginWith')}</span>
        </div>
        <div className="flex flex-col gap-4 w-full max-w-sm">
          <GoogleAuthButton onSuccess={handleGoogleSuccess} />
          <FacebookAuthButton onSuccess={handleFacebookLoginSuccess} onFailure={handleFacebookLoginFailure} />
        </div>
      </div>
    </div>
  );
};

export default Login;
