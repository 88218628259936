import React, { useState } from "react";
import Modal from "../../../components/Modal";



interface Company {
  id: number;
  user: string;
  service: string;
  category: string;
  fechasolicitud: Date;
  fechaservicio: Date;
  empresa: string;
  precio: number;  
  telefonoempresa: string;
  telefonousuario: string;
  accioncancelar: string;
  accionfinalizar: string;
  cancel: boolean;
  finish: boolean;
}

const TableActiveService: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([
    { id: 2356, user: "Juan Camilo", service: "Guardería de mascotas", category: "Mascotas", fechasolicitud: new Date("2024-06-25"), fechaservicio: new Date("2024-06-27"), empresa: "Pet Feliz", precio: 250000, telefonoempresa: "31452396554", telefonousuario: "31464126554", cancel: false, finish: false, accioncancelar: "Cancelar", accionfinalizar: "Finalizar" },
    { id: 2564, user: "Esteban", service: "Lavado de carro", category: "Automotriz", fechasolicitud: new Date("2024-06-22"), fechaservicio: new Date("2024-06-25"), empresa: "car poblado", precio: 25000, telefonoempresa: "31465841154", telefonousuario: "31465521554", cancel: false, finish: true, accioncancelar: "Cancelar", accionfinalizar: "Finalizar"},
    { id: 2444, user: "Diego Alejandro", service: "Corte de cabello", category: "Belleza", fechasolicitud: new Date("2024-05-12"), fechaservicio: new Date("2024-05-15"), empresa: "belleza y algo mas", precio: 120000, telefonoempresa: "31465896554", telefonousuario: "31465896554", cancel: false, finish: false, accioncancelar: "Cancelar", accionfinalizar: "Finalizar"},
    { id: 2699, user: "Estefanía Garzes", service: "Cita médica", category: "Salud", fechasolicitud: new Date("2024-06-30"), fechaservicio: new Date("2024-07-2"), empresa: "clinica soma", precio: 86000, telefonoempresa: "31465847754", telefonousuario: "31145896554", cancel: false, finish: false, accioncancelar: "Cancelar", accionfinalizar: "Finalizar"},
    { id: 2554, user: "Carlos Perez", service: "Guardería de mascotas", category: "Mascotas", fechasolicitud: new Date("2024-05-7"), fechaservicio: new Date("2024-05-28"), empresa: "Mi mascota feliz", precio: 150000, telefonoempresa: "31465896554", telefonousuario: "31465896554", cancel: false, finish: false, accioncancelar: "Cancelar", accionfinalizar: "Finalizar"},
    { id: 2357, user: "Carlos Perez", service: "Claces de guitarra", category: "Musica", fechasolicitud: new Date("2024-07-11"), fechaservicio: new Date("2024-07-14"), empresa: "los parranderos", precio: 60000, telefonoempresa: "34445896554", telefonousuario: "31544896554", cancel: false, finish: false, accioncancelar: "Cancelar", accionfinalizar: "Finalizar"},
    { id: 2657, user: "Carlos Perez", service: "Fabricación de mueble", category: "Carpinteria", fechasolicitud: new Date("2024-05-20"), fechaservicio: new Date("2024-05-25"), empresa: "muebles y algo mas", precio: 1200000, telefonoempresa: "31465896554", telefonousuario: "35555896554", cancel: false, finish: false, accioncancelar: "Cancelar", accionfinalizar: "Finalizar"},
    { id: 6357, user: "Carlos Perez", service: "Peluqueria Mascotas", category: "Mascotas", fechasolicitud: new Date("2024-05-10"), fechaservicio: new Date("2024-05-27"), empresa: "Pet cambriedge", precio: 160000, telefonoempresa: "31466856554", telefonousuario: "31565896554", cancel: false, finish: false, accioncancelar: "Cancelar", accionfinalizar: "Finalizar"},
    { id: 7357, user: "Carlos Perez", service: "Corte de cabello", category: "Belleza", fechasolicitud: new Date("2024-06-24"), fechaservicio: new Date("2024-06-28"), empresa: "Alisados duran", precio: 120000, telefonoempresa: "31465896544", telefonousuario: "32225896554", cancel: true, finish: true, accioncancelar: "Cancelar", accionfinalizar: "Finalizar"},
    { id: 2577, user: "Carlos Perez", service: "Cita médica", category: "Salud", fechasolicitud: new Date("2024-02-15"), fechaservicio: new Date("2024-02-12"), empresa: "Laboratoria gen", precio: 700000, telefonoempresa: "31465892564", telefonousuario: "31465896555", cancel: false, finish: false, accioncancelar: "Cancelar", accionfinalizar: "Finalizar"},
  ]);

  const sortCompanies = (criteria: string) => {   
    const sortedCompanies = [...companies];

    switch (criteria) {
      case "fechasolicitud":
        sortedCompanies.sort((a, b) => a.fechasolicitud.getTime() - b.fechasolicitud.getTime());
        break;
        case "fechaservicio":
        sortedCompanies.sort((a, b) => a.fechaservicio.getTime() - b.fechaservicio.getTime());
        break; 
      case "precio":
        sortedCompanies.sort((a, b) => b.precio - a.precio);
        break;
      default:
        break;
    }

    setCompanies(sortedCompanies);
    
  };
  const [open, setOpen] = useState(false);
 

  return (
    <div className=" "> 
      <h1 className="text-lg font-bold text-center">Seguimiento de Servicios Activas</h1>
      <div className="flex items-center gap-4 mb-4">
        <label htmlFor="sort" className="text-sm font-medium">
          Ordenar por:
        </label>
        <select
          id="sort"
          className="border rounded p-2 text-sm"
          onChange={(e) => sortCompanies(e.target.value)}
        >
          <option value="">Selecciona un criterio</option>
          <option value="fechasolicitud">fecha solicitud</option>
          <option value="fechaservicio">fecha servicio</option>
          <option value="precio">precio</option>         
        </select>
      </div>
      <div className="overflow-y-auto max-h-96 border rounded-md">
        <table className="table-auto w-full border-collapse border border-gray-200 max-h-96">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-2 py-3 text-xs text-left">ID</th>
              <th className="px-8 py-3 text-xs text-center">Usuario</th>
              <th className="px-8 py-3 text-xs text-center">Servicio</th>
              <th className="px-2 py-3 text-xs text-center">Categoría</th>
              <th className="px-2 py-3 text-xs text-center">Fecha Solicitud</th>
              <th className="px-2 py-3 text-xs text-center">Fecha Servicio</th>
              <th className="px-8 py-3 text-xs text-center">Empresas</th>
              <th className="px-2 py-3 text-xs text-center">Precio</th>
              <th className="px-2 py-3 text-xs text-center">Teléfono Empresa</th>
              <th className="px-2 py-3 text-xs text-center">Teléfono Usuario</th>
              <th className="px-2 py-3 text-xs text-center">Cancelado usuario</th>
              <th className="px-2 py-3 text-xs text-center">Finalizado Empresa</th>
              <th className="px-2 py-3 text-xs text-center">Cancelar</th>
              <th className="px-2 py-3 text-xs text-center">Finalizar</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((company) => (
              <tr key={company.id} className={`border-t ${company.cancel? "bg-slate-200" : "bg-white"} ${company.finish? "bg-slate-200" : "bg-white"} ${company.fechasolicitud > company.fechaservicio? "bg-slate-200" : "bg-white"}`}>
                <td className="px-2 py-4 text-xs text-left text-gray-700 ">{company.id}</td>
                <td className="truncate px-8 py-4 text-xs text-center text-gray-700 ">{company.user}</td>
                <td className="truncate px-8 py-4 text-xs text-center text-gray-700">{company.service}</td>
                <td className="px-2 py-4 text-xs text-center text-gray-700">{company.category}</td>
                <td className="px-2 py-4 text-xs text-center text-gray-700">{company.fechasolicitud.toLocaleDateString("es-CO")}</td>
                <td className="px-2 py-4 text-xs text-center text-gray-700">{company.fechaservicio.toLocaleDateString("es-CO")}</td>
                <td className="px8- py-4 text-xs text-center text-gray-700">{company.empresa}</td>
                <td className="px-2 py-4 text-xs text-center text-gray-700">{company.precio}</td>
                <td className="px-2 py-4 text-xs text-center text-gray-700">{company.telefonoempresa}</td>
                <td className="px-2 py-4 text-xs text-center text-gray-700">{company.telefonousuario}</td>
                <td className="px-2 py-4 text-xs text-center text-gray-700">{company.cancel? "Si": "No"}</td>     
                <td className="px-2 py-4 text-xs text-center text-gray-700">{company.finish? "Si": "No"}</td>
                <td className="px-2 py-4 text-xs text-center text-red-500"><button onClick={() => setOpen(true)}>
                  Cancelar
                </button></td>
                <td className="px-4 py-4 text-xs text-center text-green-400"><button onClick={() => setOpen(true)}>
                  Finalizar
                </button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableActiveService;

