
import React from "react";
import CardAdminXervix from "./components/card_admin";
import TableResumenDashboard from "./components/table_resumen";
import Sidebar from "./components/sidebar_admin";

const AdminPage: React.FC = () => {
  return (
    <div className="flex p-6 bg-gray-50 max-h-screen">
<Sidebar/>

<div className="ml-10 w-full mt-8 ">   
<div className="mt-8 grid grid-cols-3 gap-4 mb-8">
        <div className="px-4 py-8 bg-pink-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Usuarios Totales</h3>
          <p className="text-xl font-bold">2651</p>
        </div>
        <div className="px-4 py-8 bg-green-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Ganancias totales netas</h3>
          <p className="text-xl font-bold">25'350.000</p>
        </div>
        <div className="px-4 py-8 bg-blue-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Empresas totales</h3>
          <p className="text-xl font-bold">5326</p>
        </div>
        <div className="px-4 py-8 bg-yellow-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Ventas totales</h3>
          <p className="text-xl font-bold">$250'350.000</p>
        </div>
        <div className="px-4 py-8 bg-green-200 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Número de servicios</h3>
          <p className="text-xl font-bold">5.632</p>
        </div>
        <div className="px-4 py-8 bg-pink-200 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Número de suscripciones</h3>
          <p className="text-xl font-bold">2.526</p>
        </div>
      </div>     
     
      <TableResumenDashboard />
    </div>

    </div>

    
  );
};

export default AdminPage;