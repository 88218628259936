import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCategoryStore from '../store/useCategoryStore'; // Importar el store de categorías
import { useNavigate } from 'react-router-dom';
import routesConfig from '../config/routes.config'; // Asegúrate de que routesConfig sea correcto
import useOnboardingStore from '../store/useOnboardingStore'; // Store para el submit
import { NextStepGuardRouteString } from '../guards/NextStepGuard';

interface CategoryServices {
  categoryName: string;
  categoryId: string;
  services: { serviceId: string; serviceName: string }[];
}

const ServiceOnboardingPage: React.FC = () => {
  const { t } = useTranslation();
  const { availableServices, fetchAvailableServices, isLoading } = useCategoryStore();
  const { submitServices } = useOnboardingStore(); // Método para enviar los servicios seleccionados
  const [groupedCategories, setGroupedCategories] = useState<CategoryServices[]>([]);
  const [selectedServices, setSelectedServices] = useState<{ [key: string]: string[] }>({});
  const [accordionOpen, setAccordionOpen] = useState<{ [key: string]: boolean }>({});
  const navigate = useNavigate();

  useEffect(() => {
    // Llamada para obtener los servicios disponibles
    fetchAvailableServices();
  }, [fetchAvailableServices]);

  // Efecto para agrupar los servicios por categorías una vez se cargan
  useEffect(() => {
    if (availableServices) {
      const grouped = availableServices.reduce((acc: CategoryServices[], service) => {
        const categoryIndex = acc.findIndex((c) => c.categoryId === service.categoryId);
        if (categoryIndex > -1) {
          acc[categoryIndex].services.push({ serviceId: service.serviceId, serviceName: service.serviceName });
        } else {
          acc.push({
            categoryId: service.categoryId,
            categoryName: service.categoryName,
            services: [{ serviceId: service.serviceId, serviceName: service.serviceName }],
          });
        }
        return acc;
      }, []);
      setGroupedCategories(grouped);

      // Inicializamos el estado de los acordeones (cerrados por defecto)
      const initialAccordionState = grouped.reduce((acc, category) => {
        acc[category.categoryId] = false; // Cada categoría cerrada por defecto
        return acc;
      }, {} as { [key: string]: boolean });
      setAccordionOpen(initialAccordionState);
    }
  }, [availableServices]);

  // Función para manejar la selección de servicios
  const toggleServiceSelection = (categoryId: string, serviceId: string) => {
    setSelectedServices((prev) => {
      const selectedInCategory = prev[categoryId] || [];
      if (selectedInCategory.includes(serviceId)) {
        return {
          ...prev,
          [categoryId]: selectedInCategory.filter((id) => id !== serviceId),
        };
      }
      return {
        ...prev,
        [categoryId]: [...selectedInCategory, serviceId],
      };
    });
  };

  // Contar servicios seleccionados por categoría
  const getSelectedServiceCount = (categoryId: string) => {
    return selectedServices[categoryId]?.length || 0;
  };

  // Función para manejar la apertura y cierre de los acordeones
  const toggleAccordion = (categoryId: string) => {
    setAccordionOpen((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId], // Cambiar el estado de abierto/cerrado
    }));
  };

  // Función para manejar el botón continuar
  const handleContinue = () => {
    // Recolectar todos los servicios seleccionados
    const allSelectedServices = Object.values(selectedServices).flat();
    submitServices(allSelectedServices); // Llamada al método de envío

    const redirectTo = NextStepGuardRouteString();
    navigate(redirectTo); 
  };

  // Validación para asegurarse de que cada categoría tenga al menos un servicio seleccionado
  const canContinue = () => {
    return groupedCategories.every((category) => getSelectedServiceCount(category.categoryId) > 0);
  };

  // Muestra un estado de carga mientras se obtienen los datos
  if (isLoading) {
    return <div className="text-center">Cargando...</div>;
  }

  // Verifica que `groupedCategories` no sea undefined
  if (!groupedCategories || groupedCategories.length === 0) {
    return <div className="text-center">No se encontraron categorías disponibles.</div>;
  }

  return (
    <div className="min-h-screen p-8 bg-white">
      <div className="text-center mb-8">
        <h1 className="text-3xl font-bold">{t('Bienvenido al sistema de registro de profesional XERVIX')}</h1>
      </div>

      {groupedCategories.map((category) => (
        <div key={category.categoryId} className="mb-4">
          {/* Encabezado del acordeón */}
          <div
            className="flex justify-between items-center bg-gray-100 p-4 rounded-lg cursor-pointer"
            onClick={() => toggleAccordion(category.categoryId)}
          >
            <h2 className="text-lg font-semibold">{category.categoryName}</h2>
            <span className="text-sm text-gray-500">
              {getSelectedServiceCount(category.categoryId)} {t('servicios seleccionados')}
            </span>
          </div>

          {/* Servicios de la categoría (mostrados solo si el acordeón está abierto) */}
          {accordionOpen[category.categoryId] && (
            <div className="grid grid-cols-2 gap-4 mt-2">
              {category.services.map((service) => (
                <div
                  key={service.serviceId}
                  className={`cursor-pointer px-4 py-2 border rounded-lg ${
                    selectedServices[category.categoryId]?.includes(service.serviceId)
                      ? 'bg-green-100 text-green-700 border-green-400'
                      : 'bg-white text-gray-700 border-gray-300'
                  }`}
                  onClick={() => toggleServiceSelection(category.categoryId, service.serviceId)}
                >
                  {service.serviceName}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      {/* Botón Continuar */}
      <div className="flex justify-center mt-8">
        <button
          className="bg-red-500 text-white px-8 py-3 rounded-lg"
          onClick={handleContinue}
          disabled={!canContinue()} // Deshabilitado hasta que todas las categorías tengan un servicio seleccionado
        >
          {t('Continuar')}
        </button>
      </div>
    </div>
  );
};

export default ServiceOnboardingPage;
