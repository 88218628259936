
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import useAuthStore from "../store/useAuthStore";

const LoginButton: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuthStore();

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <>
      {isAuthenticated ? (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-medium">{user.name}</p>
          <UserCircleIcon className="size-8 stroke-textBrand fill-bgBrandLight dark:fill-transparent dark:stroke-bgBrandLight" />
        </div>
      ) : (
        <button
          onClick={handleLoginClick}
          className="flex flex-row gap-2 items-center hover:text-cText dark:text-dark-cText"
        >
          <p className="font-medium">{t("login")}</p>
          <UserCircleIcon className="size-8 stroke-textBrand fill-bgBrandLight dark:fill-transparent dark:stroke-bgBrandLight" />
        </button>
      )}
    </>
  );
};

export default LoginButton;