import React from "react";

// Definimos la interfaz para los datos de calificación
interface RatingData {
  rating: number;
  count: number;
}

interface RatingSummaryProps {
  data: RatingData[];
  totalReviews: number;
}

const RatingSummary: React.FC<RatingSummaryProps> = ({ data, totalReviews }) => {
  // Calcula el porcentaje para cada barra
  const calculatePercentage = (count: number): number => {
    return (count / totalReviews) * 100;
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-sm font-bold text-center text-blue-900 mb-2">
        Raiting y Opiniones ({totalReviews})
      </h2>
      <p className="text-gray-600 text-center mb-4 text-xs">Resumen</p>
      <div className="space-y-2">
        {data.map((item) => (
          <div key={item.rating} className="flex items-center">
            <span className="w-6 text-gray-800 font-medium text-right mr-2">
              {item.rating}
            </span>
            <div className="flex-1 h-2 bg-gray-200 rounded-lg overflow-hidden">
              <div
                className="h-full bg-yellow-400"
                style={{
                  width: `${calculatePercentage(item.count)}%`,
                }}
              ></div>
            </div>
            <span className="w-28 text-gray-600 ml-2 text-sm">
              {item.count} Reviews
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RatingSummary;
