import React from 'react';
// import FacebookLogin from 'react-facebook-login';
import { useTranslation } from 'react-i18next';

interface Props {
  onSuccess?: (data: any) => void;
  onFailure?: (error: any) => void;
}

const FacebookAuthButton: React.FC<Props> = ({ onSuccess, onFailure }) => {
  const { t } = useTranslation();

  const responseFacebook = (response: any) => {
    if (response.accessToken) {
      if (onSuccess) {
        onSuccess(response);
      }
    } else {
      if (onFailure) {
        onFailure(response);
      }
    }
  };

  return (
    // <FacebookLogin
    //   appId={process.env.REACT_APP_FACEBOOK_APP_ID || ''}
    //   autoLoad={false}
    //   fields="name,email,picture"
    //   callback={responseFacebook}
    //   cssClass="w-full bg-blue-600 text-white p-2 rounded-xl flex items-center justify-center gap-2"
    //   icon="fa-facebook"
    //   textButton={t('facebook')}
    // />
    <button
      className="w-full bg-blue-600 text-white p-2 rounded-xl flex items-center justify-center gap-2"
      onClick={() => responseFacebook({ accessToken: 'dummy_access_token' })}
    >
      <i className="fa fa-facebook" />
      {t('facebook')}
    </button>
  );
};

export default FacebookAuthButton;
