import React from "react";

type DocumentEmpresa = {
  name: string;
  type: string;
  size: string;
  owner: string;
  link: string;
};

type DocumentationProps = {
  documents: DocumentEmpresa[];
  maxImages: number;
};

const Documentation: React.FC<DocumentationProps> = ({
  documents,
  maxImages,
}) => {
  return (
    <div className="max-w-md mx-auto bg-white p-6 shadow-md rounded-md">
      {/* Sección de Documentación */}
      <div className="mb-6">
        <h2 className="text-sm font-semibold text-gray-800">
          Documentación UI UX DESIGN
        </h2>
        <p className="text-xs text-gray-600 mt-2 leading-5">
          Recuerda que no puedes comenzar a vender tus servicios dentro de
          Xervix hasta que no envíes todos los documentos.
        </p>
        <div className="mt-4 space-y-4">
          {documents.map((doc, index) => (
            <div
              key={index}
              className="flex items-center justify-between p-2  bg-gray-100 rounded-md"
            >
              {/* Icono del documento */}
              <div className="flex items-center space-x-3">
                <div
                  className={`w-8 h-8 flex items-center justify-center text-white rounded ${
                    doc.type === "PDF" ? "bg-red-500" : "bg-green-500"
                  }`}
                >
                  {doc.type}
                </div>
                {/* Información del documento */}
                <div>
                  <p className="text-xs font-medium mb-1">{doc.name}</p>
                  <p className="text-xs text-gray-500">
                    {doc.owner} • {doc.size}
                  </p>
                </div>
              </div>
              {/* Botón de descarga */}
              <a
                href={doc.link}
                className="text-blue-500 text-sm font-medium hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a>
            </div>
          ))}
        </div>
      </div>

      {/* Sección de Imágenes */}
      <div>
        <h2 className="text-sm font-semibold text-gray-800">
          Imágenes UI UX DESIGN
        </h2>
        <p className="text-xs text-gray-600 mt-2 leading-5">
          Aquí podrás subir las imágenes que serán mostradas en tu perfil a la
          hora de que un usuario consulte tu propuesta. Es muy importante que
          cuentes con imágenes que muestren tu empresa y los servicios que has
          realizado.
        </p>
        <div className="mt-4 border-dashed border-2 border-gray-300 rounded-md flex flex-col items-center justify-center h-32">
          <p className="text-sm text-gray-500">
            Puedes subir hasta {maxImages} imágenes
          </p>
          <button className="mt-2 text-blue-500 text-sm font-medium hover:underline">
            Galería
          </button>
        </div>
      </div>
    </div>
  );
};

export default Documentation;
