
import React from "react";
import Sidebar from "./components/sidebar_admin";
import TableReelsAprove from "./components/table_reels_resume";
import CardAdminXervix from "./components/card_admin";
import { Link } from "react-router-dom";
import TableReelsActive from "./components/table_reels_active";

const AdminPageMarket: React.FC = () => {
  return (
    <div className="flex p-6 bg-gray-50 min-h-screen">
    <Sidebar />

    <div className="ml-10 w-full">     

    <div className="flex justify-end text-sm mt-10">    
      <Link to="/admin/marketplace" className="px-4"><span>Reels Activos</span></Link>
      <Link to="/admin/marketplace/resume" className="px-4"><span>Aprobación de Reels</span></Link>
     
    </div>

    <div className="mt-8 grid grid-cols-1 gap-4 mb-8">
        <div className="px-4 py-8 bg-blue-100  text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Reels sin aprobar</h3>
          <p className="text-xl font-bold">26</p>
        </div>
              
      </div> 
      
    
     <TableReelsActive />
   </div>

 </div>  
  );
};

export default AdminPageMarket;