import React, { useState } from "react";

interface User {
  logo: string;
  name: string;
  email: string;
  phone: string;
  city: string;
  subscription: string;
  requests: number;
  purchases: number;
  joinDate: string;
}

// Generar datos de ejemplo
const generateSampleData = (): User[] => {
  const cities = [
    "Medellín",
    "Bogotá",
    "Bucaramanga",
    "Cali",
    "Cartagena",
    "Barranquilla",
    "Santa Marta",
  ];
  const subscriptions = ["Free", "Standar", "Premium"];

  const sampleData: User[] = Array.from({ length: 20 }, (_, index) => ({
    logo: ["🔵", "🟠", "🟡", "🟢"][index % 4],
    name: `Usuario ${index + 1}`,
    email: `usuario${index + 1}@example.com`,
    phone: `31465${Math.floor(Math.random() * 100000)}`,
    city: cities[index % cities.length],
    subscription: subscriptions[index % subscriptions.length],
    requests: Math.floor(Math.random() * 100),
    purchases: Math.floor(Math.random() * 5000000) + 1000000,
    joinDate: `2023-${Math.floor(Math.random() * 12 + 1)
      .toString()
      .padStart(2, "0")}-${Math.floor(Math.random() * 28 + 1)
      .toString()
      .padStart(2, "0")}`,
  }));

  return sampleData;
};

const sampleData = generateSampleData();

const cities = [
  "Medellín",
  "Bogotá",
  "Bucaramanga",
  "Cali",
  "Cartagena",
  "Barranquilla",
  "Santa Marta",
];

const subscriptions = ["Free", "Standar", "Premium"];

const UserTable: React.FC = () => {
  const [filteredData, setFilteredData] = useState<User[]>(sampleData);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [selectedSubscription, setSelectedSubscription] = useState<string | null>(null);
  const [sortField, setSortField] = useState<string | null>(null);

  const handleFilter = (filterType: string, value: string) => {
    let data = sampleData;

    if (filterType === "city") {
      setSelectedCity(value);
      data = data.filter((user) => user.city === value);
    }

    if (filterType === "subscription") {
      setSelectedSubscription(value);
      data = data.filter((user) => user.subscription === value);
    }

    setFilteredData(data);
  };

  const handleSort = (field: string) => {
    setSortField(field);
    const sortedData = [...filteredData].sort((a, b) => {
      if (field === "purchases") return b.purchases - a.purchases;
      if (field === "request") return b.requests - a.requests;
      if (field === "joinDate") return new Date(b.joinDate).getTime() - new Date(a.joinDate).getTime();
      return 0;
    });

    setFilteredData(sortedData);
  };

  const resetFilters = () => {
    setFilteredData(sampleData);
    setSelectedCity(null);
    setSelectedSubscription(null);
    setSortField(null);
  };

  return (
    <div className="py-4 px-12 bg-white shadow-lg rounded-md h-5/6 ">
      <h1 className=" font-bold mb-4 text-center">Listado maestro de Usuarios</h1>
      <div className="flex items-center justify-between mb-4">
        {/* Filtros */}
        <div className="flex items-center gap-4">
          <button className="text-cyan-500 flex items-center gap-1 text-xs">
             Filtros
          </button>
          <select
            className={`border rounded-md p-2 ${selectedCity ? "text-pink-500" : ""}text-xs`}
            onChange={(e) => handleFilter("city", e.target.value)}
            value={selectedCity || ""}
          >
            <option value="">Ciudad</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
          <select
            className={`border rounded-md p-2 ${selectedSubscription ? "text-pink-500" : ""} text-xs`}
            onChange={(e) => handleFilter("subscription", e.target.value)}
            value={selectedSubscription || ""}
          >
            <option value="">Suscripción</option>
            {subscriptions.map((sub) => (
              <option key={sub} value={sub}>
                {sub}
              </option>
            ))}
          </select>
          <button className="text-red-500 text-xs" onClick={resetFilters}>
            Reset Filter
          </button>
        </div>

        {/* Ordenar */}
        <div className="flex items-center gap-4">
          <button
            className={`text-cyan-500 ${sortField === "joinDate" ? "text-pink-500" : ""} text-xs`}
            onClick={() => handleSort("joinDate")}
          >
            Fecha
          </button>
          <button
            className={`text-cyan-500 ${sortField === "purchases" ? "text-pink-500" : ""} text-xs`}
            onClick={() => handleSort("purchases")}
          >
            Comprado
          </button>
          <button
            className={`text-cyan-500 ${sortField === "request" ? "text-pink-500" : ""} text-xs`}
            onClick={() => handleSort("request")}
          >
            # Solicitudes
          </button>
        </div>
      </div>

      {/* Tabla con scroll */}
      <div className="overflow-y-auto max-h-96 border">
        <table className="w-full border-collapse border border-gray-200 rounded-md overflow-hidden">
          <thead>
            <tr className="text-gray-700">
              <th className="border text-sm px-4 py-3">Logo</th>
              <th className="border text-sm px-4 py-3">Nombre</th>
              <th className="border text-sm px-4 py-3">Email</th>
              <th className="border text-sm px-4 py-3">Teléfono</th>
              <th className="border text-sm px-4 py-3">Ciudad</th>
              <th className="border text-sm px-4 py-3">Suscripción</th>
              <th className="border text-sm px-4 py-3"># Solicitudes</th>
              <th className="border text-sm px-4 py-3">Comprado</th>
              <th className="border text-sm px-4 py-3">Fecha de ingreso</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((user, index) => (
              <tr key={index} className="text-center">
                <td className="border py-4 px-2 text-xs">{user.logo}</td>
                <td className="border py-4 px-2 text-xs">{user.name}</td>
                <td className="border py-4 px-8 text-xs">{user.email}</td>
                <td className="border py-4 px-2 text-xs">{user.phone}</td>
                <td className="border py-4 px-2 text-xs">{user.city}</td>
                <td className="border py-4 px-2 text-xs">{user.subscription}</td>
                <td className="border py-4 px-2 text-xs">{user.requests}</td>
                <td className="border py-4 px-2 text-xs">{user.purchases.toLocaleString()}</td>
                <td className="border py-4 px-2 text-xs">{user.joinDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserTable;
