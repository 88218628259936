import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Bars3Icon, Squares2X2Icon, UserIcon, DocumentTextIcon, ShoppingCartIcon, HeartIcon, ChartBarIcon, ChartPieIcon } from '@heroicons/react/24/outline';

const Sidebar: React.FC = () => {
  return (
    <div className={`flex "w-64" transition-all duration-300 bg-white h-screen shadow-lg`}>
      <div className="flex flex-col w-full p-2">
        {/* Header */}
        <div className="flex items-center justify-between p-4">
          <h1 className={`text-xl font-bold text-red-500 "block" transition-all`}>
            XERVIX
          </h1>         
          <Bars3Icon className="h-6 w-6" />      
        </div>

        {/* Navigation Links */}
        <nav className="flex-1 p-2">
          <ul className="space-y-4">
            <li>
              <Link to="/admin" className="flex items-center gap-5 text-sm text-gray-700 mb-8 hover:text-red-500">
                <Squares2X2Icon className="h-5 w-5" />
                <span className={`block transition-all`}>Dashboard</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/user" className="flex items-center gap-5 text-sm text-gray-700 mb-8 hover:text-red-500">
                <UserIcon className="h-5 w-5" />
                <span className={`block transition-all`}>User</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/empresas" className="flex items-center gap-5 text-sm text-gray-700 mb-8 hover:text-red-500">
                <DocumentTextIcon className="h-5 w-5" />
                <span className={`block transition-all`}>Empresas</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/solicitudes" className="flex items-center gap-5 text-sm text-gray-700 mb-8 hover:text-red-500">
                <ChartPieIcon className="h-5 w-5" />
                <span className={`block transition-all`}>Solicitudes</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/opiniones" className="flex items-center gap-5 text-sm text-gray-700 mb-8 hover:text-red-500">
                <ShoppingCartIcon className="h-5 w-5" />
                <span className={`block transition-all`}>Comentarios</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/marketplace" className="flex items-center gap-5 text-sm text-gray-700 mb-8 hover:text-red-500">
                <ShoppingCartIcon className="h-5 w-5" />
                <span className={`block transition-all`}>MarketPlace</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/pagos" className="flex items-center gap-5 text-sm text-gray-700 mb-8 hover:text-red-500">
                <HeartIcon className="h-5 w-5" />
                <span className={`block transition-all`}>Historial de pagos</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/estadisticas" className="flex items-center gap-5 text-sm text-gray-700 mb-8 hover:text-red-500">
                <ChartBarIcon className="h-5 w-5" />
                <span className={`block transition-all`}>Estadísticas</span>
              </Link>
            </li>          
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
