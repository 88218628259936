import React from 'react';

const ReelsPage: React.FC = () => {
    return (
        <div>
            <h1>Reels Page</h1>
            <p>Welcome to the Reels Page!</p>
        </div>
    );
};

export default ReelsPage;