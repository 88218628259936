import { create } from 'zustand';
import categoryService from '../services/category.service';
import professionalService from '../services/professional.service';
import { CategoryResponse, CategoryQuerySearchResponse } from '../types/category.types';

interface CategoryStoreState {
  categories: CategoryResponse | null;
  searchResults: CategoryQuerySearchResponse[];
  availableServices: any[]; // Estado para almacenar los servicios disponibles
  isLoading: boolean;
  error: string | null;
  fetchCategories: () => Promise<void>;
  searchCategories: (query: string) => Promise<any[]>;
  fetchAvailableServices: () => Promise<void>; // Método para obtener los servicios disponibles
}

const useCategoryStore = create<CategoryStoreState>((set) => ({
  categories: null,
  searchResults: [],
  availableServices: [],
  isLoading: false,
  error: null,

  fetchCategories: async () => {
    set({ isLoading: true });
    try {
      const categories = await categoryService.fetchCategories();
      set({ categories, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },

  searchCategories: async (query: string): Promise<any[]> => {
    set({ isLoading: true });
    try {
      const results = await categoryService.searchCategories(query);
      set({ isLoading: false });
      return results || []; // Devuelve los resultados directamente
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
      return [];
    }
  },

  fetchAvailableServices: async () => {
    set({ isLoading: true });
    try {
      const availableServices = await professionalService.fetchAvailableServices();
      set({ availableServices, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },
}));

export default useCategoryStore;
