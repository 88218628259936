import React from "react";
import { Category } from "@/types/category.types";
import { useNavigate } from "react-router-dom";
import routesConfig from "../config/routes.config";

interface CategoryCardProps {
  category: Category;
}

export const CategoryCard: React.FC<CategoryCardProps> = ({
  category,
}) => {
  const navigate = useNavigate();

  const handleClick = (category: Category) => {
    navigate(routesConfig.categoryServicesPage.replace(':id', category.categoryId), { state: { category } });
  };

  const totalServices = category.subcategories.reduce((acc, subcategory) => acc + subcategory.services.length, 0);

  return (
    <div key={category.categoryId} className="flex-none w-64 cursor-pointer select-none" onClick={() => handleClick(category)}>
        <div className="bg-white dark:bg-gray-800 rounded-3xl shadow-md overflow-hidden p-4 xl:p-2">
        <img src={category.imageUrl} alt={category.name} className="w-full h-60 xl:h-64 object-cover rounded-3xl p-2" />
        <div className="p-4">
            <div className='flex flex-col xl:flex-row items-start xl:items-center justify-between gap-2'>
            <h3 className="font-semibold text-xl text-cText dark:text-dark-cText">{category.name}</h3>
            <p className="text-sm text-cTextSecondary dark:text-dark-cTextSecondary whitespace-nowrap">{ totalServices } Servicios</p>
            </div>
            <ul className='mt-2'>
            {category.subcategories.flatMap(subcategory => subcategory).slice(0, 3).map((service: any, index: number) => (
                <li key={index} className='text-cTextSecondary dark:text-dark-cTextSecondary'>{service.name}</li>
            ))}
            </ul>
        </div>
        </div>
    </div>
  );
};