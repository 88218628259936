import { xervixApi } from '../config/axios.config';
import { API_ROUTES } from '../constants/apiRoutes';
import { ServiceResponse, ServiceQuerySearchResponse } from '../types/service.types';

const fetchServices = async (): Promise<ServiceResponse> => {
  const response = await xervixApi.get(API_ROUTES.SERVICES.FETCH_SERVICES, {
    params: {
      page: 1,
      pageSize: 1000,
    },
  });
  return response.data as ServiceResponse;
};

// Bug in Backend
const fetchService= async (serviceId: string): Promise<ServiceResponse> => {
  const response = await xervixApi.get(API_ROUTES.SERVICES.FETCH_SERVICE(serviceId));
  return response.data as ServiceResponse;
};

export default {
  fetchServices,
  fetchService,
};
