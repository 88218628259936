import React from "react";
import { ServiceFormValues } from "../types/serviceForm.types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ArrowUpTrayIcon } from "@heroicons/react/24/solid";
import { examServices } from "../data/examServices";
import { dynamicFormSchema } from "../schemas/dynamicFormSchema";
import { FormField } from "./FormField";

export const DynamicForm: React.FC = () => {
  const initialValues: ServiceFormValues = {
    patientName: "",
    phone: "",
    city: "",
    address: "",
    idealDate: "",
    idealTime: "",
    selectedExams: [],
    medicalOrder: null,
    description: "",
    requireHomeService: false,
    requireVirtualService: false,
    requireUrgentService: false,
  };

  const handleSubmit = async (values: ServiceFormValues) => {
    try {
      console.log(values);
      // Aquí iría tu lógica de envío
    } catch (error) {
      console.error("Error al enviar el formulario:", error);
    }
  };

  const needsOrder = (exams: string[]): boolean => {
    return exams.some(
      (examId) =>
        examServices.find((service: any) => service.id === examId)
          ?.requiresOrder
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={dynamicFormSchema}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={true}
    >
      {({ errors, touched, setFieldValue, values }) => (
        <Form className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow">
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Patient Name */}
              <FormField
                label="Nombre del paciente"
                name="patientName"
                placeholder="Miguel Angel Contreras"
              />

              {/* Phone */}
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Teléfono de contacto
                </label>
                <div className="flex">
                  <select className="relative h-full rounded-l-md border-r-0 border-gray-300 bg-transparent py-2 pl-3 pr-7 text-gray-500 focus:border-blue-500 focus:ring-blue-500">
                    <option>+57</option>
                  </select>
                  <Field
                    name="phone"
                    className="block w-full rounded-r-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    placeholder="304 366 25 33"
                  />
                </div>
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              {/* City */}
              <FormField
                label="Ciudad"
                name="city"
                placeholder="Medellin Colombia"
              />

              {/* Address */}
              <FormField
                label="Dirección"
                name="address"
                placeholder="Carrera 43a # 25-263"
              />

              {/* Date and Time */}
              <div className="grid grid-cols-2 gap-4">
                <FormField
                  type="date"
                  label="Fecha ideal"
                  name="idealDate"
                />
                <FormField
                  label="Hora ideal"
                  name="idealTime"
                  type="time"
                />
              </div>
            </div>

            {/* Exam Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Elige los examenes que no se encuentran en tu orden médica
              </label>
              <div className="mt-2 space-y-2">
                {values.selectedExams.map((exam) => (
                  <div
                    key={exam}
                    className="flex items-center justify-between p-2 bg-gray-50 rounded"
                  >
                    <span>
                      {examServices.find((s: any) => s.id === exam)?.name}
                    </span>
                    <button
                      type="button"
                      onClick={() => {
                        const newExams = values.selectedExams.filter(
                          (e) => e !== exam
                        );
                        setFieldValue("selectedExams", newExams);
                      }}
                      className="text-red-500 hover:text-red-700"
                    >
                      ×
                    </button>
                  </div>
                ))}
                <select
                  onChange={(e) => {
                    if (e.target.value) {
                      const newExams = [
                        ...values.selectedExams,
                        e.target.value,
                      ];
                      setFieldValue("selectedExams", newExams);
                      e.target.value = "";
                    }
                  }}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="">Elige tu examen</option>
                  {examServices.map((service: any) => (
                    <option key={service.id} value={service.id}>
                      {service.name}
                    </option>
                  ))}
                </select>
                <ErrorMessage
                  name="selectedExams"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
            </div>

            {/* Medical Order Upload */}
            {needsOrder(values.selectedExams) && (
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Adjunta tu orden médica
                </label>
                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                  <div className="space-y-1 text-center">
                    {values.medicalOrder ? (
                      <div className="flex flex-col items-center">
                        <div className="flex items-center space-x-2">
                          <ArrowUpTrayIcon className="size-8 text-green-500" />
                          <span className="text-sm text-gray-600">
                            {values.medicalOrder.name}
                          </span>
                        </div>
                        <button
                          type="button"
                          onClick={() => setFieldValue("medicalOrder", null)}
                          className="mt-2 text-sm text-red-500 hover:text-red-700"
                        >
                          Eliminar archivo
                        </button>
                      </div>
                    ) : (
                      <>
                        <ArrowUpTrayIcon className="mx-auto size-12 text-gray-400" />
                        <div className="flex text-sm text-gray-600">
                          <label className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                            <span>Click aquí para subir el archivo</span>
                            <input
                              type="file"
                              className="sr-only"
                              onChange={(event) => {
                                const file =
                                  event.currentTarget.files?.[0] || null;
                                if (file) {
                                  // Verificar el tamaño del archivo (2MB = 2 * 1024 * 1024 bytes)
                                  if (file.size > 2 * 1024 * 1024) {
                                    alert(
                                      "El archivo no puede ser mayor a 2MB"
                                    );
                                    return;
                                  }
                                  // Verificar el tipo de archivo
                                  const allowedTypes = [
                                    "image/jpeg",
                                    "image/png",
                                    "application/pdf",
                                  ];
                                  if (!allowedTypes.includes(file.type)) {
                                    alert(
                                      "Solo se permiten archivos JPG, PNG y PDF"
                                    );
                                    return;
                                  }
                                }
                                setFieldValue("medicalOrder", file);
                              }}
                              accept=".pdf,.jpg,.jpeg,.png"
                            />
                          </label>
                        </div>
                        <p className="text-xs text-gray-500">
                          El documento no puede pesar mas de 2mb
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <ErrorMessage
                  name="medicalOrder"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
            )}

            {/* Description */}
            <FormField
              label="Tienes algo que agregar?"
              name="description"
              as="textarea"
              rows={4}
            />

            {/* Service Options */}
            <div className="space-y-4">
              <FormField
                label="Requiero el examen en mi domicilio"
                name="requireHomeService"
                type="checkbox"
              />
              <FormField
                label="Requiero el examen de manera virtual"
                name="requireVirtualService"
                type="checkbox"
              />
              <FormField
                label="Servicio Urgente"
                name="requireUrgentService"
                type="checkbox"
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-end">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                SOLICITAR
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default DynamicForm;
