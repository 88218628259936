
import React from "react";
import CardAdminXervix from "./components/card_admin";
import TableResumenDashboard from "./components/table_resumen";
import Sidebar from "./components/sidebar_admin";
import CardEstadisticas from "./components/card_estadisticas";

const AdminPageEstadisiticas: React.FC = () => {
  return (
    <div className="flex p-6 bg-gray-50 min-h-screen">
     <Sidebar />
 <div className="w-full">          
     
      <CardEstadisticas />
    </div>

  </div>   
  );
};

export default AdminPageEstadisiticas;