import React from "react";
import CardAdminXervix from "./components/card_admin";

import Sidebar from "./components/sidebar_admin";
import TableOpinionesAprove from "./components/table_opiniones_aprove";
import { Link } from "react-router-dom";

const AdminPageOpiniones: React.FC = () => {
  return (
    <div className="flex p-6 bg-gray-50 min-h-screen">
    <Sidebar />
<div className="ml-10 w-full">     

<div className="flex justify-end text-sm mt-10">    
      <Link to="/admin/opiniones" className="px-4"><span>Aprobación de comentarios</span></Link>
      <Link to="/admin/opiniones/historial" className="px-4"><span>Historial</span></Link>
     
    </div>

     <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6 mb-8 py-2">
        <CardAdminXervix
          title="Opiniones sin aprobar"
          value={254}
          icon={<span role="img" aria-label="user">👤</span>}
          description="8.5% Mas que el mes pasado"
          trendColor="text-green-600"/>    
                  
    </div>        
    
     <TableOpinionesAprove />
   </div>

 </div>   
  );
};

export default AdminPageOpiniones;