import React, { useState } from "react";
import CardCategoriesEmpresas from "./components/categories_card_empresas";
import ProfileCard from "./components/card_portada_empresas";
import ProfileFormEmpresas from "./components/card_form_empresas";
import Documentation from "./components/card_documents_empresas";


const CompanyProfile: React.FC = () => {

  const [profile, setProfile] = useState({
    image: "https://via.placeholder.com/150", // Sustituye por la URL de la imagen
    name: "Maderas y Maderas",
    followers: 400,
    following: 1000,
    likes: 120,
    story:
      "Un día cuenta de que quería hacer algo más que simplemente escuchar música; quería compartir su amor por ella con el mundo Un día cuenta de que quería hacer algo más que simplemente escuchar música; quería compartir su amor por ella con el mundo... Un día cuenta de que quería hacer algo más que simplemente escuchar música; quería compartir su amor por ella con el mundo...",
  });  

  const [profileData, setProfileData] = useState({
    legalName: "Juan Sebastian Martinez",
    desiredName: "Juan Se",
    email: "j.martinez@gmail.com",
    phone: "+57 3056355254",
    emergencyContact: "Emilio Gómez Ruiz",
    address: "Carrera 50 # 54-85",
    country: "Colombia",
    city: "Medellín",
    businessId: "25411541",
  });

  const documents =  [
    {
      name: "Cédula Representante.pdf",
      type: "PDF",
      size: "4.5 MB",
      owner: "Maderas y maderas",
      link: "/path-to-document.pdf",
    },
    {
      name: "Cámara de comercio",
      type: "XLS",
      size: "12.4 MB",
      owner: "Maderas y maderas",
      link: "/path-to-document.xls",
    },
    {
      name: "Certificado bancario",
      type: "XLS",
      size: "12.4 MB",
      owner: "Maderas y maderas",
      link: "/path-to-document.xls",
    },
    {
      name: "RUT",
      type: "PDF",
      size: "12.4 MB",
      owner: "Maderas y maderas",
      link: "/path-to-document.xls",
    },
  ];

  const handleEditStory = (newStory: string) => {
    setProfile((prevProfile) => ({ ...prevProfile, story: newStory }));
  };

  const handleEdit = (field: string, value: string) => {
    setProfileData((prevData) => ({ ...prevData, [field]: value }));
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="flex w-full">
        <div className="flex flex-col w-2/3 ">
        <div className="flex w-full">
              <div className="w-1/2 ">
                  <ProfileCard profile={profile} onEditStory={handleEditStory} /> 
                </div>
                <div className="w-1/2">
                  <Documentation documents={documents} maxImages={20} />
                </div>
        </div>   
           <div className="p-5">
             <CardCategoriesEmpresas/>
          </div>              
           
        </div>
        <div className=" w-1/3">
        <ProfileFormEmpresas data={profileData} onEdit={handleEdit}/>
        <div className="w-full my-auto bg-green-200 p-6 shadow-md rounded-md h-40 mt-6">

          <h1 className="text-center mt-10 font-bold text-green-800">       EMPRESA INACTIVA</h1>
         
        </div>        

            
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
