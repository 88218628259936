import React, { useState } from "react";

// Definimos el tipo para cada fila de la tabla
interface RowData {
  id: string; 
  categoria: string;
  empresa: string;
  fechaReels: string; // Se almacena como string en formato de fecha
  descripcion: string;
  titulo: string;

}

const TableReelsHistory: React.FC = () => {
  const [data, setData] = useState<RowData[]>([
    {
      id: "00001",     
      empresa: "Maderas y maderas",
      categoria: "Carpinetria",
      fechaReels: "2025-10-04",
      titulo: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      descripcion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",   
     
   
    },
    {
        id: "00002",     
        empresa: "Bellas Artes",
        categoria: "Arte",
        fechaReels: "2024-02-04",
        titulo: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        descripcion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",   
     
    },
    {
        id: "00003",     
        empresa: "Clinica Soma",
        categoria: "Consulta medica",
        fechaReels: "2024-08-04",
        titulo: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        descripcion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",   
     
    },
    {
        id: "00004",     
        empresa: "Pet Cambriedge",
        categoria: "Guarderia",
        fechaReels: "2025-01-04",
        titulo: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        descripcion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",   
     
      },
      {
        id: "00005",     
        empresa: "Pets Love",
        categoria: "Guarderia",
        fechaReels: "2024-09-16",
        titulo: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        descripcion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",   
     
      },
      {
        id: "00006",     
        empresa: "Lavado de carro",
        categoria: "Automotriz",
        fechaReels: "2024-08-04",
        titulo: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        descripcion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",   
    
      },
      {
        id: "00007",     
        empresa: "Alisados",
        categoria: "Belleza",
        fechaReels: "2024-09-04",
        titulo: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        descripcion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",   
     
      },
  ]);

  const [sortKey, setSortKey] = useState<keyof RowData | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  // Función para ordenar los datos
  const handleSort = (key: keyof RowData) => {
    const order = sortKey === key && sortOrder === "asc" ? "desc" : "asc";
    const sortedData = [...data].sort((a, b) => {    
     
      return order === "asc"
        ? String(a[key]).localeCompare(String(b[key]))
        : String(b[key]).localeCompare(String(a[key]));
    });
    setSortKey(key);
    setSortOrder(order);
    setData(sortedData);
  };

  // Función para eliminar una fila
  const handleDelete = (id: string) => {
    const filteredData = data.filter((item) => item.id !== id);
    setData(filteredData);
  };

  return (
    <div className="">
      <div className="grid grid-cols-1 gap-4 mb-8">
        <div className="px-4 py-8 bg-pink-100  text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Reels sin aprobar</h3>
          <p className="text-xl font-bold">26</p>
        </div>
              
      </div> 
      <h1 className="text-lg font-bold text-center mb-10">Seguimiento y aprobación de Reels</h1>

      <div className="flex items-center gap-4 mb-4">
        <label htmlFor="sort" className="text-sm font-medium">
          Ordenar por:
        </label>

        <div className="flex px-4 py-2 text-xs text-center border rounded-lg border-gray-300">
            <button onClick={(e) => handleSort("categoria")}>Categoria</button>
        </div>
        <div className="flex px-4 py-2 text-xs text-center border rounded-lg border-gray-300">
            <button onClick={(e) => handleSort("empresa")}>empresa</button>
        </div>
        <div className="flex px-4 py-2 text-xs text-center border rounded-lg border-gray-300">
            <button onClick={(e) => handleSort("fechaReels")}>fecha</button>
        </div>       
      </div>

      <div className="overflow-y-auto border rounded-md w-full">
      <table className="table-auto w-full border-collapse border border-gray-200 ">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-4 text-xs text-left text-gray-700"> Id </th>
            <th className="px-4 py-4 text-xs text-center text-gray-700"> Empresa </th>
            <th className="px-4 py-3 text-xs text-center"> Categoría </th>
            <th className="px-4 py-3 text-xs text-center">Fecha</th>
            <th className="px-4 py-3 text-xs text-center">Titulo</th>
            <th className="px-4 py-3 text-xs text-center"> Descripción</th>
        
            <th className="px-4 py-3 text-xs text-center">Acción</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id} className="border-t border-gray-300">
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.id}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.empresa}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.categoria}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700"> {new Date(item.fechaReels).toLocaleDateString()}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.titulo}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.descripcion}</td>              
              <td className="px-4 py-4 text-xs text-center text-gray-700">
                <button className="bg-green-500 text-white px-3 py-1 rounded mr-2 hover:bg-red-600"
                  onClick={() => handleDelete(item.id)}
                > Seleccionar
                </button>
                
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      </div>


      
    </div>
  );
};

export default TableReelsHistory;
