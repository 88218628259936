import React, { useState } from "react";

// Definimos el tipo para cada fila de la tabla
interface RowData {
  id: string; 
  categoria: string;
  empresa: string;
  fechaReels: string; // Se almacena como string en formato de fecha
  descripcion: string;
  likes: number;
  compartidos: number;
  favoritos: number;
  titulo: string;
  hashtags: string;
}

const TableReelsActive: React.FC = () => {
  const [data, setData] = useState<RowData[]>([
    {
      id: "00001",     
      empresa: "Maderas y maderas",
      categoria: "Carpinetria",
      fechaReels: "2025-10-04",
      titulo: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      descripcion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",   
      hashtags: "#Medellin",
      likes: 15,
      compartidos: 120,
      favoritos: 21,
   
    },
    {
        id: "00002",     
        empresa: "Bellas Artes",
        categoria: "Arte",
        fechaReels: "2024-02-04",
        titulo: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        descripcion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",   
        hashtags: "#Medellin",
        likes: 8,
        compartidos: 45,
        favoritos: 32,
    },
    {
        id: "00003",     
        empresa: "Clinica Soma",
        categoria: "Consulta medica",
        fechaReels: "2024-08-04",
        titulo: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        descripcion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",   
        hashtags: "#medicina",
        likes: 84,
        compartidos: 25,
        favoritos: 37,
    },
    {
        id: "00004",     
        empresa: "Pet Cambriedge",
        categoria: "Guarderia",
        fechaReels: "2025-01-04",
        titulo: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        descripcion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",   
        hashtags: "#Medellin",
        likes: 8,
        compartidos: 45,
        favoritos: 32,
      },
      {
        id: "00005",     
        empresa: "Pets Love",
        categoria: "Guarderia",
        fechaReels: "2024-09-16",
        titulo: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        descripcion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",   
        hashtags: "#Medellin",
        likes: 81,
        compartidos: 84,
        favoritos: 321,
      },
      {
        id: "00006",     
        empresa: "Lavado de carro",
        categoria: "Automotriz",
        fechaReels: "2024-08-04",
        titulo: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        descripcion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",   
        hashtags: "#Medellin",
        likes: 25,
        compartidos: 14,
        favoritos: 561,
      },
      {
        id: "00007",     
        empresa: "Alisados",
        categoria: "Belleza",
        fechaReels: "2024-09-04",
        titulo: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        descripcion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",   
        hashtags: "#Salon",
        likes: 14,
        compartidos: 845,
        favoritos: 428,
      },
  ]);

  const [sortKey, setSortKey] = useState<keyof RowData | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  // Función para ordenar los datos
  const handleSort = (key: keyof RowData) => {
    const order = sortKey === key && sortOrder === "asc" ? "desc" : "asc";
    const sortedData = [...data].sort((a, b) => {
      if (key === "likes") {
        return order === "asc" ? a[key] - b[key] : b[key] - a[key];
      }
      if (key === "compartidos") {
        return order === "asc" ? a[key] - b[key] : b[key] - a[key];
      }
      if (key === "favoritos") {
        return order === "asc" ? a[key] - b[key] : b[key] - a[key];
      }
      return order === "asc"
        ? String(a[key]).localeCompare(String(b[key]))
        : String(b[key]).localeCompare(String(a[key]));
    });
    setSortKey(key);
    setSortOrder(order);
    setData(sortedData);
  };

  // Función para eliminar una fila
  const handleDelete = (id: string) => {
    const filteredData = data.filter((item) => item.id !== id);
    setData(filteredData);
  };

  return (
    <div className="">
      <h1 className="text-lg font-bold text-center mb-10">Reels Activos</h1>

      <div className="flex items-center gap-4 mb-4">
        <label htmlFor="sort" className="text-sm font-medium">
          Ordenar por:
        </label>

        <div className="flex px-4 py-2 text-xs text-center border rounded-lg border-gray-300">
            <button onClick={(e) => handleSort("categoria")}>Categoria</button>
        </div>
        <div className="flex px-4 py-2 text-xs text-center border rounded-lg border-gray-300">
            <button onClick={(e) => handleSort("empresa")}>empresa</button>
        </div>
        <div className="flex px-4 py-2 text-xs text-center border rounded-lg border-gray-300">
            <button onClick={(e) => handleSort("fechaReels")}>fecha</button>
        </div>
        <div className="flex px-4 py-2 text-xs text-center border rounded-lg border-gray-300">
            <button onClick={(e) => handleSort("likes")}>Likes</button>
        </div>
        <div className="flex px-4 py-2 text-xs text-center border rounded-lg border-gray-300">
            <button onClick={(e) => handleSort("compartidos")}>Compartidos</button>
        </div>
        <div className="flex px-4 py-2 text-xs text-center border rounded-lg border-gray-300">
            <button onClick={(e) => handleSort("favoritos")}>Favoritos</button>
        </div>
      </div>

      <div className="overflow-y-auto  border rounded-md">
      <table className="table-auto w-full border-collapse border border-gray-200 ">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-4 text-xs text-left text-gray-700"> Id </th>
            <th className="px-4 py-4 text-xs text-center text-gray-700"> Empresa </th>
            <th className="px-4 py-3 text-xs text-center"> Categoría </th>
            <th className="px-4 py-3 text-xs text-center">Fecha</th>
            <th className="px-4 py-3 text-xs text-center">Titulo</th>
            <th className="px-4 py-3 text-xs text-center"> Descripción</th>
            <th className="px-4 py-3 text-xs text-center">Hashtags</th>
            <th className="px-4 py-3 text-xs text-center">Likes</th>
            <th className="px-4 py-3 text-xs text-center">Compartidos</th>
            <th className="px-4 py-3 text-xs text-center">Favoritos</th>
            <th className="px-4 py-3 text-xs text-center">Acción</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id} className="border-t border-gray-300">
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.id}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.empresa}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.categoria}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700"> {new Date(item.fechaReels).toLocaleDateString()}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.titulo}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.descripcion}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.hashtags}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.likes}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.compartidos}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.favoritos}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">
                <button className="bg-red-500 text-white px-3 py-1 rounded mr-2 hover:bg-red-600"
                  onClick={() => handleDelete(item.id)}
                > Eliminar
                </button>
               
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      </div>


      
    </div>
  );
};

export default TableReelsActive;
