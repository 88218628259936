import React from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div
      onClick={onClose}
      className={`fixed inset-0 flex z-40 justify-center items-center transition-colors ${
        isOpen ? "visible bg-black/60" : "invisible"
      }`}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={`bg-white rounded-xl shadow p-6 transition-all ${
          isOpen ? "scale-100 opacity-100" : "scale-125 opacity-0"
        }`}
      >
        <button
          onClick={onClose}
          className="absolute top-2 right-2 p-1 rounded-lg text-gray-400 bg-white hover:bg-gray-50 hover:text-gray-600"
        >
          Close
        </button>
        <h2 className="text-xl font-bold text-cText dark:text-dark-cText">{title}</h2>
        <div className="text-cTextSecondary dark:text-dark-cTextSecondary">{children}</div>
      </div>
    </div>
  );
};

export default Modal;

export {};