import React, { useState } from "react";

// Definimos el tipo para cada fila de la tabla
interface RowData {
  id: string;
  usuario: string;
  servicio: string;
  empresa: string;
  fechaServicio: string; // Se almacena como string en formato de fecha
  opinion: string;
  calificacion: number;
  telefonoUsuario: string;
  telefonoEmpresa: string;
}

const TableOpinionesHistory: React.FC = () => {
  const [data, setData] = useState<RowData[]>([
    {
      id: "00001",
      usuario: "Christine Brooks",
      servicio: "Corte de cabello",
      empresa: "Maderas y maderas",
      fechaServicio: "2019-09-04",
      opinion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      calificacion: 4,
      telefonoUsuario: "3005653224",
      telefonoEmpresa: "3005653224",
    },
    {
      id: "00002",
      usuario: "Rosie Pearson",
      servicio: "Cita con psicólogo",
      empresa: "Maderas y maderas",
      fechaServicio: "2019-05-28",
      opinion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      calificacion: 1,
      telefonoUsuario: "3005653224",
      telefonoEmpresa: "3005653224",
    },
    {
      id: "00003",
      usuario: "Rosie Pearson",
      servicio: "Cita con psicólogo",
      empresa: "Maderas y maderas",
      fechaServicio: "2019-05-28",
      opinion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
      calificacion: 5,
      telefonoUsuario: "3005653224",
      telefonoEmpresa: "3005653224",
    },
    {
        id: "00004",
        usuario: "Rosie Pearson",
        servicio: "Cita con psicólogo",
        empresa: "Maderas y maderas",
        fechaServicio: "2019-05-28",
        opinion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        calificacion: 5,
        telefonoUsuario: "3005653224",
        telefonoEmpresa: "3005653224",
      },
      {
        id: "00005",
        usuario: "Rosie Pearson",
        servicio: "Cita con psicólogo",
        empresa: "Maderas y maderas",
        fechaServicio: "2019-05-28",
        opinion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        calificacion: 5,
        telefonoUsuario: "3005653224",
        telefonoEmpresa: "3005653224",
      },
      {
        id: "00006",
        usuario: "Rosie Pearson",
        servicio: "Cita con psicólogo",
        empresa: "Maderas y maderas",
        fechaServicio: "2019-05-28",
        opinion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        calificacion: 5,
        telefonoUsuario: "3005653224",
        telefonoEmpresa: "3005653224",
      },
      {
        id: "00007",
        usuario: "Rosie Pearson",
        servicio: "Cita con psicólogo",
        empresa: "Maderas y maderas",
        fechaServicio: "2019-05-28",
        opinion: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
        calificacion: 5,
        telefonoUsuario: "3005653224",
        telefonoEmpresa: "3005653224",
      },
  ]);

  const [sortKey, setSortKey] = useState<keyof RowData | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");

  // Función para ordenar los datos
  const handleSort = (key: keyof RowData) => {
    const order = sortKey === key && sortOrder === "asc" ? "desc" : "asc";
    const sortedData = [...data].sort((a, b) => {
      if (key === "calificacion") {
        return order === "asc" ? a[key] - b[key] : b[key] - a[key];
      }
      return order === "asc"
        ? String(a[key]).localeCompare(String(b[key]))
        : String(b[key]).localeCompare(String(a[key]));
    });
    setSortKey(key);
    setSortOrder(order);
    setData(sortedData);
  };

  // Función para eliminar una fila
  const handleDelete = (id: string) => {
    const filteredData = data.filter((item) => item.id !== id);
    setData(filteredData);
  };


  return (
    <div className="">
      <h1 className="text-lg font-bold text-center mb-10">Historial de calificaciones Activas</h1>

      <div className="flex items-center gap-4 mb-4 justify-between">

        <div className="flex gap-4">
        <label htmlFor="sort" className="text-sm font-medium">
          Ordenar por:
        </label>

        <div className="flex px-4 py-2 text-xs text-center border rounded-lg border-gray-300">
            <button onClick={(e) => handleSort("usuario")}>Usuario</button>
        </div>
        <div className="flex px-4 py-2 text-xs text-center border rounded-lg border-gray-300">
            <button onClick={(e) => handleSort("empresa")}>empresa</button>
        </div>
        <div className="flex px-4 py-2 text-xs text-center border rounded-lg border-gray-300">
            <button onClick={(e) => handleSort("fechaServicio")}>fechaServicio</button>
        </div>
        <div className="flex px-4 py-2 text-xs text-center border rounded-lg border-gray-300">
            <button onClick={(e) => handleSort("calificacion")}>calificacion</button>
        </div>

        </div>

        <h5 className="mr-2">{"Cantidad de comentarios = " + data.length}</h5>
       


      </div>

      <div className="overflow-y-auto border max-h-96 rounded-md">
      <table className="table-auto w-full border-collapse border border-gray-200 max-h-96 ">
        <thead>
          <tr className="bg-gray-100">
            <th className="px-4 py-4 text-xs text-left text-gray-700"> Usuario </th>
            <th className="px-4 py-4 text-xs text-center text-gray-700"> Servicio </th>
            <th className="px-4 py-3 text-xs text-center"> Empresa </th>
            <th className="px-4 py-3 text-xs text-center">Fecha Servicio </th>
            <th className="px-4 py-3 text-xs text-center">Opinión</th>
            <th className="px-4 py-3 text-xs text-center"> Calificación</th>
            <th className="px-4 py-3 text-xs text-center">Teléfono Usuario</th>
            <th className="px-4 py-3 text-xs text-center">Teléfono Empresa</th>
            <th className="px-4 py-3 text-xs text-center">Acción</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.id} className="border-t border-gray-300">
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.usuario}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.servicio}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.empresa}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700"> {new Date(item.fechaServicio).toLocaleDateString()}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.opinion}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.calificacion}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.telefonoUsuario}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">{item.telefonoEmpresa}</td>
              <td className="px-4 py-4 text-xs text-center text-gray-700">
                <button className="bg-red-500 text-white px-3 py-1 rounded mr-2 hover:bg-red-600"
                  onClick={() => handleDelete(item.id)}
                > Eliminar
                </button>
               
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      </div>


      
    </div>
  );
};

export default TableOpinionesHistory;
