import { xervixApi } from '../config/axios.config';
import { API_ROUTES } from '../constants/apiRoutes';
import { CategoryQuerySearchResponse, CategoryResponse } from '../types/category.types';

const fetchCategories = async (): Promise<CategoryResponse> => {
  const response = await xervixApi.get(API_ROUTES.CATEGORIES.FETCH_CATEGORIES, {
    params: {
      page: 1,
      pageSize: 1000,
    },
  });
  return response.data as CategoryResponse;
};

const fetchCategory = async (categoryId: string): Promise<CategoryResponse> => {
  const response = await xervixApi.get(API_ROUTES.CATEGORIES.FETCH_CATEGORY(categoryId));
  return response.data as CategoryResponse;
}

const searchCategories = async (query: string): Promise<CategoryQuerySearchResponse[]> => {
  const response = await xervixApi.get(API_ROUTES.CATEGORIES.SEARCH_CATEGORIES, {
    params: { query },
  });
  return response.data as CategoryQuerySearchResponse[];
};

export default {
  fetchCategories,
  fetchCategory,
  searchCategories,
};
