// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import routesConfig from '../routes/routesConfig';

// const DashboardPage: React.FC = () => {
//     const navigate = useNavigate();

//     const handleLogout = () => {
//         localStorage.removeItem('accessToken');
//         localStorage.removeItem('refreshToken');
//         localStorage.removeItem('nextStep');
//         localStorage.removeItem('authResponse');
//         navigate(routesConfig.login);
//     };

//     return (
//         <div>
//             <h1>Dashboard</h1>
//             <p>Welcome to the Dashboard!</p>
//             <button onClick={handleLogout}>Logout</button>

//         </div>
//     );
// };

// export default DashboardPage;

import React, { useState } from "react";
import {
  Bars3Icon,
  XMarkIcon,
  Squares2X2Icon,
  UserIcon,
  ChatBubbleLeftIcon,
  BriefcaseIcon,
  DocumentTextIcon,
  ShoppingCartIcon,
  HeartIcon,
  Cog6ToothIcon,
  CameraIcon,
  SunIcon,
  MoonIcon,
  ArrowLeftStartOnRectangleIcon,
} from "@heroicons/react/24/outline";
import { Navigate, useNavigate } from "react-router-dom";
import routesConfig from "../config/routes.config";
import { on } from "events";

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();
  return (
    <aside
      className={`bg-white fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } lg:translate-x-0`}
    >
      <div className="h-full px-3 py-4 overflow-y-auto flex flex-col justify-between">
        <div>
          <div className="flex items-center justify-between mb-5">
            <span className="text-2xl font-semibold text-red-500">XERVIX</span>
            <button onClick={toggleSidebar} className="lg:hidden">
              <XMarkIcon />
            </button>
          </div>
          <ul className="space-y-2 font-medium">
            {[
              { icon: <Squares2X2Icon />, text: "Dashboard" },
              { icon: <UserIcon />, text: "User" },
              { icon: <ChatBubbleLeftIcon />, text: "Messages" },
              { icon: <BriefcaseIcon />, text: "Servicios" },
              { icon: <DocumentTextIcon />, text: "Mis propuestas" },
              { icon: <ShoppingCartIcon />, text: "MarketPlace" },
              { icon: <HeartIcon />, text: "Historial de pagos" },
              { icon: <Cog6ToothIcon />, text: "Configuraciones" },
              {
                icon: <ArrowLeftStartOnRectangleIcon />,
                text: "Salir",
                onClick: () => {
                  localStorage.removeItem("accessToken");
                  localStorage.removeItem("refreshToken");
                  localStorage.removeItem("nextStep");
                  localStorage.removeItem("authResponse");
                  navigate(routesConfig.login);
                },
              },
            ].map((item, index) => (
              <li key={index} onClick={item?.onClick}>
                <a
                  href="#"
                  className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100"
                >
                  {item.icon}
                  <span className="ml-3">{item.text} </span>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex items-center p-2 mt-auto bg-gray-100 rounded-lg">
          <img
            src="/placeholder.svg?height=40&width=40"
            alt="User"
            className="w-10 h-10 rounded-full mr-3"
          />
          <div>
            <p className="text-sm font-medium">Maria Fernanda</p>
            <p className="text-xs text-gray-500">Belleza</p>
          </div>
        </div>
      </div>
    </aside>
  );
};

interface HeaderProps {
  toggleSidebar: () => void;
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}

const Header: React.FC<HeaderProps> = ({
  toggleSidebar,
  isDarkMode,
  toggleDarkMode,
}) => {
  return (
    <header className="bg-white border-b border-gray-200 px-4 py-2.5 flex justify-between items-center">
      <div className="flex items-center">
        <button onClick={toggleSidebar} className="lg:hidden mr-4">
          <Bars3Icon />
        </button>
        <button className="bg-green-500 text-white px-4 py-2 rounded-md">
          CUENTA ACTIVA
        </button>
      </div>
      <div className="flex items-center space-x-4">
        <div className="flex space-x-2">
          <button className="px-4 py-2 rounded-md border border-gray-300">
            Navegar como Usuario
          </button>
          <button className="px-4 py-2 rounded-md border border-gray-300 text-red-500">
            Navegar como Empresa
          </button>
        </div>
        <div className="flex items-center space-x-2">
          <span>Maria Fernanda Uribe</span>
          <img
            src="/placeholder.svg?height=32&width=32"
            alt="Profile"
            className="w-8 h-8 rounded-full"
          />
        </div>
        <button onClick={toggleDarkMode}>
          {isDarkMode ? <SunIcon /> : <MoonIcon />}
        </button>
      </div>
    </header>
  );
};

const UserProfile = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6">
      <div className="relative">
        <img
          src="/placeholder.svg?height=120&width=120"
          alt="Maria Paulina Ruiz"
          className="w-24 h-24 rounded-full"
        />
        <button className="absolute bottom-0 right-0 bg-white rounded-full p-1 shadow-md">
          <CameraIcon />
        </button>
      </div>
      <div className="text-center md:text-left flex-grow">
        <h2 className="text-2xl font-bold">Hola! Maria</h2>
        <p className="text-gray-600">Tus registros generales son:</p>
        <div className="grid grid-cols-3 gap-4 mt-4">
          {[
            { label: "Cotizaciones", value: "195" },
            { label: "Seguidores", value: "1209" },
            { label: "Servicios", value: "84" },
            { label: "Likes", value: "2542" },
            { label: "Ventas", value: "12'540.000" },
            { label: "Reels", value: "45" },
          ].map((item, index) => (
            <div key={index} className="bg-gray-100 p-2 rounded-md text-center">
              <p className="font-semibold">{item.value}</p>
              <p className="text-sm text-gray-600">{item.label}</p>
            </div>
          ))}
        </div>
      </div>
      <img
        src="/placeholder.svg?height=80&width=80"
        alt="Hand"
        className="w-20 h-20"
      />
    </div>
  );
};

const ActiveServices = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Servicios activos</h3>
      <div className="space-y-4">
        {[1, 2, 3].map((_, index) => (
          <div
            key={index}
            className="flex items-center justify-between bg-gray-100 p-4 rounded-md"
          >
            <div className="flex items-center space-x-4">
              <img
                src="/placeholder.svg?height=48&width=48"
                alt="Service provider"
                className="w-12 h-12 rounded-full"
              />
              <div>
                <p className="font-semibold">Maria Fernanda Patiño</p>
                <p className="text-sm text-gray-600">Manicure básico</p>
                <p className="text-xs text-gray-500">1 día</p>
              </div>
            </div>
            <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
              Atendiendo
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const UpgradeCard = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md flex items-center justify-between">
      <div>
        <h3 className="text-xl font-semibold mb-4">
          🚀 ¡Lleva tu experiencia al siguiente nivel! 🚀
        </h3>
        <p className="mb-2">
          ¿Quieres aprovechar funciones avanzadas que te harán la vida más
          fácil?
        </p>
        <p className="mb-4">
          ¡Inscríbete ahora en [Nombre de tu startup] y accede a herramientas
          exclusivas diseñadas para optimizar tu tiempo y potenciar tu negocio.
          ¡Es simple, rápido y lleno de ventajas!
        </p>
        <button className="bg-red-500 text-white px-4 py-2 rounded-md">
          VER PLANES
        </button>
      </div>
      <img
        src="/placeholder.svg?height=120&width=120"
        alt="Upgrade illustration"
        className="w-32 h-32"
      />
    </div>
  );
};

const CustomerSatisfaction = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Satisfacción del cliente</h3>
      <img
        src="/placeholder.svg?height=200&width=400"
        alt="Customer Satisfaction Chart"
        className="w-full h-40 object-cover rounded-md"
      />
      <div className="flex justify-between mt-4">
        <div>
          <p className="text-sm text-gray-600">Mes pasado</p>
          <p className="font-semibold">4.8</p>
        </div>
        <div>
          <p className="text-sm text-gray-600">Este mes</p>
          <p className="font-semibold">4.9</p>
        </div>
      </div>
    </div>
  );
};

const TopServices = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold mb-4">Top de servicios</h3>
      <table className="w-full">
        <thead>
          <tr className="text-left">
            <th className="py-2">#</th>
            <th className="py-2">Nombre</th>
            <th className="py-2">Popularidad</th>
            <th className="py-2">Ventas</th>
          </tr>
        </thead>
        <tbody>
          {[1, 2, 3].map((num) => (
            <tr key={num} className="border-t">
              <td className="py-2">{num}</td>
              <td className="py-2">Disney</td>
              <td className="py-2">
                <div className="bg-red-200 h-2 w-20 rounded-full"></div>
              </td>
              <td className="py-2">
                <span className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                  2
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default function Dashboard() {
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
  const toggleDarkMode = () => setIsDarkMode(!isDarkMode);
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("nextStep");
    localStorage.removeItem("authResponse");
    navigate(routesConfig.login);
  };
  return (
    <div className={`flex h-screen ${isDarkMode ? "dark" : ""}`}>
      <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="flex-1 flex flex-col overflow-hidden lg:ml-64">
        <Header
          toggleSidebar={toggleSidebar}
          isDarkMode={isDarkMode}
          toggleDarkMode={toggleDarkMode}
        />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100 p-4">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div className="lg:col-span-2">
              <UserProfile />
            </div>
            <div className="lg:row-span-2">
              <ActiveServices />
            </div>
            <div className="lg:col-span-2">
              <UpgradeCard />
            </div>
            <div>
              <CustomerSatisfaction />
            </div>
            <div className="lg:col-span-2">
              <TopServices />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
