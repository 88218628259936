import { ExamService } from "../types/serviceForm.types";

export const examServices: ExamService[] = [
  {
    id: "glucose",
    name: "Glucosa en ayunas",
    requiresOrder: true,
    requiresDocuments: true,
    requiresDescription: true,
  },
  {
    id: "cholesterol",
    name: "Perfil lipídico",
    requiresOrder: true,
    requiresDocuments: true,
    requiresDescription: true,
  },
  {
    id: "hemogram",
    name: "Hemograma",
    requiresOrder: true,
    requiresDocuments: true,
    requiresDescription: true,
  },
  {
    id: "urinalysis",
    name: "Examen de orina",
    requiresOrder: true,
    requiresDocuments: true,
    requiresDescription: true,
  },
  {
    id: "serology",
    name: "Serología",
    requiresOrder: true,
    requiresDocuments: true,
    requiresDescription: true,
  },
  {
    id: "pregnancy",
    name: "Prueba de embarazo",
    requiresOrder: true,
    requiresDocuments: true,
    requiresDescription: true,
  },
  {
    id: "thyroid",
    name: "Perfil tiroideo",
    requiresOrder: true,
    requiresDocuments: true,
    requiresDescription: true,
  },
  {
    id: "liver",
    name: "Perfil hepático",
    requiresOrder: true,
    requiresDocuments: true,
    requiresDescription: true,
  },
  {
    id: "kidney",
    name: "Perfil renal",
    requiresOrder: true,
    requiresDocuments: true,
    requiresDescription: true,
  },
  {
    id: "covid",
    name: "Prueba de COVID-19",
    requiresOrder: true,
    requiresDocuments: true,
    requiresDescription: true,
  },
  {
    id: "blood",
    name: "Grupo sanguíneo",
    requiresOrder: true,
    requiresDocuments: true,
    requiresDescription: true,
  },
  {
    id: "allergy",
    name: "Prueba de alergias",
    requiresOrder: true,
    requiresDocuments: true,
    requiresDescription: true,
  },
  {
    id: "cancer",
    name: "Marcadores tumorales",
    requiresOrder: true,
    requiresDocuments: true,
    requiresDescription: true,
  },
  {
    id: "std",
    name: "Prueba de ETS",
    requiresOrder: true,
    requiresDocuments: true,
    requiresDescription: true,
  },
];
