import React from 'react';
import { useTranslation } from 'react-i18next';
import { Service } from '@/types/service.types';
import { useNavigate } from 'react-router-dom';
import routesConfig from '../config/routes.config';
import { ServiceCard } from './ServiceCard';


const TopServicesHero: React.FC<{ onButtonClick: () => void }> = ({ onButtonClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const topServices = [
    { serviceId: "1", categoryId: "1", categoryName: "Beauty", name: "Corte de uñas", price: "$5,42k", provider: "Peluquería damaris", empresas: "68", numservices: "265", subcategoryId: "sub1", subcategoryName: "Subcategory 1" },
    { serviceId: "2", categoryId: "2", categoryName: "Pet Care", name: "Guardería perros", price: "$5,42k", provider: "Pets miguel", empresas: "123", numservices: "327", subcategoryId: "sub2", subcategoryName: "Subcategory 2" },
    { serviceId: "3", categoryId: "3", categoryName: "Health", name: "Consulta Medico G.", price: "$5,42k", provider: "La soma", empresas: "42", numservices: "120", subcategoryId: "sub3", subcategoryName: "Subcategory 3" },
  ];

  const handleRequestClick = (service: any) => {
    console.log("Enviar Solicitud para:", service.name);
    onButtonClick();
  };

  return (
    <section className="py-16">
      <h2 className="text-2xl lg:text-3xl font-bold mb-6 text-center">{t("landingPage.topServicesHero.title")}</h2>
      <div className="h-1 lg:h-10"></div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 lg:gap-20 px-2 md:px-12 lg:px-24 text-center">
        {topServices.map((service, index) => (
          <ServiceCard key={index} service={service} onRequestClick={onButtonClick} />
        ))}
      </div>
    </section>
  );
}

export default TopServicesHero;