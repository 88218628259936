
import React from "react";
import CardAdminXervix from "./components/card_admin";
import TableResumenDashboard from "./components/table_resumen";
import Sidebar from "./components/sidebar_admin";
import UserTable from "./components/table_user";
import TableEmpresas from "./components/table_empresas";
import TableEmpresasDesable from "./components/table_empresas_desable";

const AdminPageEmpresas: React.FC = () => {
  return (
  <div className="flex p-6 bg-gray-50 min-h-screen">
     <Sidebar />

      <div className="ml-10 w-full">   

     <div className="mt-8 grid grid-cols-5 gap-4 mb-8">
        <div className="px-4 py-8 bg-pink-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Cantidad de empresas</h3>
          <p className="text-xl font-bold">2651</p>
        </div>
        <div className="px-4 py-8 bg-green-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Empresas activas</h3>
          <p className="text-xl font-bold">6.658</p>
        </div>
        <div className="px-4 py-8 bg-blue-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Empresas inactivas</h3>
          <p className="text-xl font-bold">256</p>
        </div>
        <div className="px-4 py-8 bg-yellow-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Empresas sin aprobación</h3>
          <p className="text-xl font-bold">1.569</p>
        </div>   
        <div className="px-4 py-8 bg-yellow-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Número de servicios</h3>
          <p className="text-xl font-bold">1.569</p>
        </div>           
      </div>          
       
            <TableEmpresas />
            <TableEmpresasDesable />        
       
    </div>

  </div>   
  );
};

export default AdminPageEmpresas;