import React from 'react';
import { Field, ErrorMessage } from 'formik';

interface FormFieldProps {
  label: string;
  name: string;
  type?: string;
  placeholder?: string;
  as?: string;
  rows?: number;
  options?: { value: string; label: string }[];
}

export const FormField: React.FC<FormFieldProps> = ({ label, name, type, placeholder, as, rows, options }) => {
  return (
    <div className="mb-4">
      {type === 'checkbox' ? (
        <div className="flex items-center justify-between">
          <span className="text-sm font-medium text-gray-700">{label}</span>
          <Field
            type={type}
            name={name}
            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
          />
        </div>
      ) : (
        <>
          <label className="block text-sm font-medium text-gray-700">
            {label}
          </label>
          <Field
            type={type}
            name={name}
            as={as}
            rows={rows}
            placeholder={placeholder}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </>
      )}
      <ErrorMessage name={name} component="div" className="text-red-500 text-sm mt-1" />
    </div>
  );
};