import React, { useEffect, useRef } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useMediaQuery } from 'react-responsive';
import useCategoryStore from '../store/useCategoryStore';
import { useSliderStore } from '../store/useSliderStore';
import { CategoryCard } from './CategoryCard';
import { Category } from '../types/category.types';
import { getMockCategories } from '../data/mockCategories';


interface Props {
  isDashboard?: boolean;
}

const CategoriesSlider: React.FC<Props> = ({ isDashboard = true }) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const { categories, fetchCategories } = useCategoryStore();
  const { scroll, startDragging, stopDragging, onDrag } = useSliderStore();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });


  /* useEffect(() => {
    fetchCategories();
  }, [fetchCategories]); */

  useEffect(() => {
    const fetchMockCategories = async () => {
      try {
        const mockCategories = await getMockCategories();
        useCategoryStore.setState({ categories: mockCategories, isLoading: false });
      } catch (error) {
        useCategoryStore.setState({ error: "Error fetching mock categories", isLoading: false });
      }
    };

    fetchMockCategories();
  }, []);

  return (
    <div className="max-w-screen-3xl ">
      {isDashboard ? (
        <div className="relative">
          {!isTabletOrMobile && (
            <button
              onClick={() => scroll('left')}
              className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-cBackground dark:bg-dark-cBackgroundSecondary dark:shadow-dark-cBackground rounded-full p-2 shadow-md"
              aria-label="Scroll left"
            >
              <ChevronLeftIcon className="size-2 xl:size-6 text-cTextSecondary dark:text-dark-cTextSecondary" />
            </button>
          )}
        <div
          ref={sliderRef}
          className="slider-container flex overflow-x-auto scrollbar-hide space-x-4 py-4 no-scrollbar px-2 xl:px-2"
          onMouseDown={isTabletOrMobile ? startDragging : undefined}
          onMouseLeave={isTabletOrMobile ? stopDragging : undefined}
          onMouseUp={isTabletOrMobile ? stopDragging : undefined}
          onMouseMove={isTabletOrMobile ? onDrag : undefined}
        >
          {categories && categories.data && categories.data.slice(0, 7).map((category: Category, index: number) => (
            <CategoryCard key={index} category={category} />
          ))}
        </div>
        {!isTabletOrMobile && (
          <button
            onClick={() => scroll('right')}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-cBackground dark:bg-dark-cBackgroundSecondary dark:shadow-dark-cBackground rounded-full p-2 shadow-lg z-10"
            aria-label="Scroll right"
          >
            <ChevronRightIcon className="size-2 xl:size-6 text-cTextSecondary dark:text-dark-cTextSecondary" />
          </button>
        )}
        </div>
    ) : (
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-2">
        {categories && categories.data && categories.data.map((category: Category, index: number) => (
          <CategoryCard key={index} category={category} />
        ))}
      </div>
    )}
    </div>
  );
};

export default CategoriesSlider;