import { create } from "zustand";

interface SliderState {
  isDragging: boolean;
  startX: number;
  scrollLeft: number;
  setIsDragging: (isDragging: boolean) => void;
  setStartX: (startX: number) => void;
  setScrollLeft: (scrollLeft: number) => void;
  scroll: (direction: "left" | "right") => void;
  startDragging: (e: React.MouseEvent<HTMLDivElement>) => void;
  stopDragging: () => void;
  onDrag: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const useSliderStore = create<SliderState>((set, get) => ({
  isDragging: false,
  startX: 0,
  scrollLeft: 0,

  setIsDragging: (isDragging) => set({ isDragging }),
  setStartX: (startX) => set({ startX }),
  setScrollLeft: (scrollLeft) => set({ scrollLeft }),

  scroll: (direction) => {
    const sliderElement = document.querySelector(
      ".slider-container"
    ) as HTMLDivElement;
    if (sliderElement) {
      const scrollAmount = 300;
      sliderElement.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  },

  startDragging: (e) => {
    const sliderElement = e.currentTarget;
    set({
      isDragging: true,
      startX: e.pageX - sliderElement.offsetLeft,
      scrollLeft: sliderElement.scrollLeft,
    });
  },

  stopDragging: () => {
    set({ isDragging: false });
  },

  onDrag: (e) => {
    const { isDragging, startX, scrollLeft } = get();
    if (!isDragging) return;
    e.preventDefault();
    const sliderElement = e.currentTarget;
    const x = e.pageX - sliderElement.offsetLeft;
    const walk = (x - startX) * 2;
    sliderElement.scrollLeft = scrollLeft - walk;
  },
}));
