import { xervixApi } from '../config/axios.config';
import { API_ROUTES } from '../constants/apiRoutes';
import { LoginResponse, RegisterRequest } from '../types/auth.types';


// Login function
const login = async (email: string, password: string): Promise<LoginResponse> => {
  try {
    const response = await xervixApi.post<LoginResponse>(`${API_ROUTES.ACCOUNT.LOGIN}`, { email, password }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      throw new Error('Unauthorized');
    }
    throw error;
  }
};

// Register function
const register = async (data: RegisterRequest): Promise<LoginResponse> => {
  try {
    const response = await xervixApi.post<LoginResponse>(`${API_ROUTES.ACCOUNT.REGISTER}`, data, {
      headers: { 'Content-Type': 'application/json', accept: '*/*' },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Google Login function - no necesita userType
const googleLogin = async (token: string): Promise<LoginResponse> => {
  try {
    const response = await xervixApi.post<LoginResponse>(`${API_ROUTES.ACCOUNT.GOOGLE_LOGIN}`, { token }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Google Register function - mantiene userType
const googleRegister = async (token: string, userType: string): Promise<LoginResponse> => {
  try {
    const response = await xervixApi.post<LoginResponse>(`${API_ROUTES.ACCOUNT.GOOGLE_REGISTER}`, { token, userType }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Reset password function
const resetPassword = async (email: string): Promise<void> => {
  try {
    const response = await xervixApi.post(`${API_ROUTES.ACCOUNT.RESET_PASSWORD}`, { email }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      throw new Error('Email not found');
    }
    throw error;
  }
};

// Verify code function
const verifyCode = async (code: string, emailOrPhone: string): Promise<void> => {
  try {
    const response = await xervixApi.post(`${API_ROUTES.ACCOUNT.VERIFY_CODE}`, { code, emailOrPhone }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.status === 400) {
      throw new Error('Invalid code');
    }
    throw error;
  }
};

// Resend verification code function
const resendVerificationCode = async (): Promise<void> => {
  try {
    const response = await xervixApi.post(`${API_ROUTES.ACCOUNT.RESEND_VERIFICATION_CODE_EMAIL}`, {}, {
      headers: { accept: '*/*' },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

// Refresh token function
const refreshToken = async (refreshToken: string): Promise<LoginResponse> => {
  try {
    const response = await xervixApi.post<LoginResponse>(`${API_ROUTES.ACCOUNT.REFRESH_TOKEN}`, { refreshToken }, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export default { 
  login, 
  register, 
  googleLogin, 
  googleRegister, 
  resetPassword, 
  verifyCode, 
  resendVerificationCode, 
  refreshToken 
};
