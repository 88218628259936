import { sign } from "crypto";

const routesConfig = {
  home: '/',
  splash: '/splash',
  login: '/login',
  dashboard: '/dashboard',
  validateEmail: '/validate-email/:sendCodeTo',
  businessOnboarding: '/business-onboarding-form-step',
  categoryOnboarding: '/category-onboarding-form-step',
  serviceOnboarding: '/service-onboarding-form-step',
  signup: '/signup',
  createAccount: '/create-account-page',
  createAccountCompany: '/create-account-page/company',
  localizationOnboarding: '/localization-onboarding-page',
  subscriptionOnboarding: '/subscription-onboarding-page',
  documentsOnboarding: '/documents-onboarding-page/:documentType',
  termsAndConditions: '/terms-and-conditions-onboarding-page',
  successCompletedOnboarding: '/success-completed-onboarding-page',
  mainRequests: '/main-requests',
  myProposals: '/mis-propuestas',
  myReels: '/myreels',
  //admin
  AdminPage: '/admin',
  AdminPageUser: '/admin/user',
  AdminPageEmpresas: '/admin/empresas',
  AdminPageSolicitudes: '/admin/solicitudes',
  AdminPageOpiniones: '/admin/opiniones',
  AdminPageMarketPlace: '/admin/marketplace',
  AdminPageHistorial: '/admin/pagos',
  AdminPagePagosGanancias: '/admin/pagos/ganancias',
  AdminPagePagospendientes: '/admin/pagos/pendientes',
  AdminPageEstadisticas: '/admin/estadisticas',
  AdminDetailCompanyPage: '/admin/empresas/idempresa',
  AdminRequestClose: '/admin/request/close',
  AdminRequestResume: '/admin/request/resume',
  AdminPageOpinionesHistorial: '/admin/opiniones/historial',
  AdminPageReelsHistorial: '/admin/marketplace/resume',

  //finaliza admin
  AdminPageDashboard: '/admin',
  mainServices: '/services',
  subscriptionProfessionalPage: '/subscription-professional-page',
  termsAndConditionsPage: '/terms-and-conditions-xervix',
  signupCustomerPage: '/signup-customer-page',
  categoriesPage: '/categories',
  categoryServicesPage: '/category/:id',
  serviceForm: '/service-form/:id',
};
export default routesConfig;
