import React from 'react';
import Sidebar from './components/sidebar_admin';
import { Link } from 'react-router-dom';

const ResumenFinanciero: React.FC = () => {
  const meses = [
    { mes: "ENERO", servicios: 1080, comisiones: 2100, suscripciones: 1560000, totales: 15, status: "Completed" },
    { mes: "FEBRERO", servicios: 1080, comisiones: 2100, suscripciones: 1560000, totales: 15, status: "Completed" },
    { mes: "MARZO", servicios: 1080, comisiones: 2100, suscripciones: 1560000, totales: 15, status: "Completed" },
    { mes: "ABRIL", servicios: 1080, comisiones: 2100, suscripciones: 1560000, totales: 15, status: "Completed" },
    { mes: "MAYO", servicios: 1080, comisiones: 2100, suscripciones: 1560000, totales: 15, status: "Completed" },
    { mes: "JUNIO", servicios: 1080, comisiones: 2100, suscripciones: 1560000, totales: 15, status: "Completed" },
    { mes: "JULIO", servicios: 1080, comisiones: 2100, suscripciones: 1560000, totales: 15, status: "Completed" },
    { mes: "AGOSTO", servicios: 1080, comisiones: 2100, suscripciones: 1560000, totales: 15, status: "Completed" },
    { mes: "SEPTIEMBRE", servicios: 1080, comisiones: 2100, suscripciones: 1560000, totales: 15, status: "Completed" },
    { mes: "NOVIEMBRE", servicios: 1080, comisiones: 2100, suscripciones: 1560000, totales: 15, status: "Completed" },  
    { mes: "DICIEMBRE", servicios: 1080, comisiones: 2100, suscripciones: 1560000, totales: 15, status: "Completed" },
  ];

  const resumen = {
    saldoBancario: 325652000,
    gananciasNetas: 269006000,
    devolucionesPendientes: 3562000,
    pagosPendientes: 8653000,
    gananciasComisiones: 13524000,
    gananciasSuscripciones: 256542000,
  };

  return (

    <div className="flex p-6 min-h-screen"> 
    
    <Sidebar />
    
     <div className="p-8  w-full">
      
     <div className="flex justify-end text-sm mt-2">    
      <Link to="/admin/pagos" className="px-4"><span>Resumen financiero</span></Link>
      <Link to="/admin/pagos/ganancias" className="px-4"><span>Ganancias</span></Link>
      <Link to="/admin/pagos/pendientes" className="px-4"><span>Pendientes</span></Link>
     
    </div>
      <h1 className="text-lg font-bold text-center mb-4">RESUMEN FINANCIERO DE XERVIX</h1>
      
      {/* Tabla de resumen */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-200 max-h-96">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-2 text-xs text-left">ID</th>
              <th className="p-2 text-xs text-center"># de servicios</th>
              <th className="p-2 text-xs text-center">Ganancias Comisiones</th>
              <th className="p-2  text-xs text-center">Ganancias suscripciones</th>
              <th className="p-2  text-xs text-center">Ganancias totales</th>
              <th className="p-2  text-xs text-center">STATUS</th>
            </tr>
          </thead>
          <tbody>
            {meses.map((mes, index) => (
              <tr key={index} className="odd:bg-white even:bg-gray-50">
                <td className="p-2  text-xs text-left text-gray-700">{mes.mes}</td>
                <td className="p-2  text-xs text-center text-gray-700">{mes.servicios}</td>
                <td className="p-2  text-xs text-center text-gray-700">{mes.comisiones}</td>
                <td className="p-2  text-xs text-center text-gray-700">{mes.suscripciones.toLocaleString()}</td>
                <td className="p-2  text-xs text-center text-gray-700">{mes.totales}</td>
                <td className="p-2  text-xs text-center text-gray-700">
                  <span className="text-sm px-3 py-1 rounded-full bg-green-100 text-green-700">
                    {mes.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Resumen financiero */}
      <div className="mt-8 grid grid-cols-3 gap-4">
        <div className="px-4 py-8 bg-pink-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Saldo en cuenta Bancaria</h3>
          <p className="text-xl font-bold">{resumen.saldoBancario.toLocaleString()}</p>
        </div>
        <div className="px-4 py-8 bg-green-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Ganancias totales netas</h3>
          <p className="text-xl font-bold">{resumen.gananciasNetas.toLocaleString()}</p>
        </div>
        <div className="px-4 py-8 bg-blue-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Devoluciones pendientes</h3>
          <p className="text-xl font-bold">{resumen.devolucionesPendientes.toLocaleString()}</p>
        </div>
        <div className="px-4 py-8 bg-yellow-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Pagos pendientes</h3>
          <p className="text-xl font-bold">{resumen.pagosPendientes.toLocaleString()}</p>
        </div>
        <div className="px-4 py-8 bg-green-200 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Ganancias por comisiones</h3>
          <p className="text-xl font-bold">{resumen.gananciasComisiones.toLocaleString()}</p>
        </div>
        <div className="px-4 py-8 bg-pink-200 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Ganancias por suscripciones</h3>
          <p className="text-xl font-bold">{resumen.gananciasSuscripciones.toLocaleString()}</p>
        </div>
      </div>
    </div>
    </div>




   
  );
};

export default ResumenFinanciero;
