import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import CategoryOnboardingPage from './pages/CategoryOnboardingPage';
import ServiceOnboardingPage from './pages/ServiceOnboardingPage';
import LocalizationOnboardingPage from './pages/LocalizationOnboardingPage';
import BusinessOnboardingPage from './pages/BusinessOnboardingPage';
import DocumentsOnboardingPage from './pages/DocumentsOnboardingPage';
import SubscriptionOnboardingPage from './pages/SubscriptionOnboardingPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import SuccessOnboardingPage from './pages/SuccessOnboardingPage';
import NextStepGuard from './guards/NextStepGuard';
import routesConfig from './config/routes.config';
import CreateAccountPage from './pages/auth/CreateAccountPage';
import LandingPage from './pages/LandingPage';
import ProposalsPage from './pages/ProposalsPage';
import ReelsPage from './pages/ReelsPage';
import RequestsPage from './pages/RequestsPage';
import ServicesPage from './pages/ServicesPage';
import SignupPage from './pages/auth/SignupPage';
import SplashPage from './pages/SplashPage';
import './i18n/config';
import LoginPage from './pages/auth/LoginPage';
import VerifyCodePage from './pages/VerifyCodePage';
import ViewTermsAndConditionsPage from './pages/ViewTermsAndConditions';
import SignupCustomerPage from './pages/auth/SignupCustomerPage';
import CategoriesPage from './pages/CategoriesPage';
import CategoryServicesPage from './pages/CategoryServicesPage';
import ServiceFormPage from './pages/ServiceFormPage';
import { GoogleOAuthProvider } from '@react-oauth/google';
import RoleGuard from './guards/RoleGuard';
import AdminPage from './pages/admin xervix/CamiloPage';
import AdminPageUser from './pages/admin xervix/AdminPageUser';
import AdminPageEmpresas from './pages/admin xervix/AdminPageEmpresas';
import AdminPageSolicitudes from './pages/admin xervix/AdminPageSolicitudes';
import AdminPageMarket from './pages/admin xervix/AdminPageMarket';
import AdminPagePagos from './pages/admin xervix/AdminPagePagos';
import AdminPageEstadisiticas from './pages/admin xervix/AdminPageEstadisticas';
import AdminDetailCompanyPage from './pages/admin xervix/detail_company_page';
import AdminRequestClose from './pages/admin xervix/AdminRequesteClosa';
import AdminRequestResume from './pages/admin xervix/Admin_request_resume';
import AdminPageOpiniones from './pages/admin xervix/AdminPage_opiniones';
import AdminPageOpinionesHistorial from './pages/admin xervix/AdminPage_opiniones_historial';
import AdminPageReelsHistorial from './pages/admin xervix/AdminPage_Reels_resume';
import AdminPagePagosGanancias from './pages/admin xervix/AdminPage_Pagos_Ganancias';
import AdminPagePagosPendientes from './pages/admin xervix/AdminPage_Pagos_Pendientes';

const App = () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  if (!clientId) {
    alert('Google Client ID is not defined');
  }

  return (
    <GoogleOAuthProvider clientId={clientId || ''}>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path={routesConfig.home} element={<LandingPage />} />
          <Route path={routesConfig.login} element={<LoginPage />} />
          <Route path={routesConfig.signupCustomerPage} element={<SignupCustomerPage />} />
          <Route path={routesConfig.createAccount} element={<CreateAccountPage />} />
          <Route path={routesConfig.signup} element={<SignupPage />} />
          <Route path={routesConfig.validateEmail} element={<VerifyCodePage />} />
          <Route path={routesConfig.categoriesPage} element={<CategoriesPage />} />
          <Route path={routesConfig.categoryServicesPage} element={<CategoryServicesPage />} />
          <Route path={routesConfig.serviceForm} element={<ServiceFormPage />} />
          <Route path={routesConfig.splash} element={<SplashPage />} />
          <Route path={routesConfig.myReels} element={<ReelsPage />} />
          <Route path={routesConfig.createAccountCompany} element={<CreateAccountPage />} />

          {/* Admin Routes - Temporarily public */}
          <Route path={routesConfig.AdminPage} element={<AdminPage />} />
          <Route path={routesConfig.AdminPageUser} element={<AdminPageUser />} />
          <Route path={routesConfig.AdminPageEmpresas} element={<AdminPageEmpresas />} />
          <Route path={routesConfig.AdminPageSolicitudes} element={<AdminPageSolicitudes />} />
          <Route path={routesConfig.AdminPageOpiniones} element={<AdminPageOpiniones />} />
          <Route path={routesConfig.AdminPageMarketPlace} element={<AdminPageMarket />} />
          <Route path={routesConfig.AdminPageHistorial} element={<AdminPagePagos />} />
          <Route path={routesConfig.AdminPagePagosGanancias} element={<AdminPagePagosGanancias />} />
          <Route path={routesConfig.AdminPagePagospendientes} element={<AdminPagePagosPendientes />} />
          <Route path={routesConfig.AdminPageEstadisticas} element={<AdminPageEstadisiticas />} />
          <Route path={routesConfig.AdminDetailCompanyPage} element={<AdminDetailCompanyPage />} />
          <Route path={routesConfig.AdminRequestClose} element={<AdminRequestClose />} />
          <Route path={routesConfig.AdminRequestResume} element={<AdminRequestResume />} />
          <Route path={routesConfig.AdminPageOpinionesHistorial} element={<AdminPageOpinionesHistorial />} />
          <Route path={routesConfig.AdminPageReelsHistorial} element={<AdminPageReelsHistorial />} />

          <Route path="*" element={<NextStepGuard />} />

          {/* Private Routes */}
          <Route
            path={routesConfig.mainServices}
            element={
              <RoleGuard allowedRoles={['USER', 'PROFESSIONAL']}>
                <ServicesPage />
              </RoleGuard>
            }
          />

          {/* Professional Routes */}
          <Route
            element={
              <RoleGuard allowedRoles={['PROFESSIONAL']}>
                <Routes>
                  <Route path={routesConfig.dashboard} element={<DashboardPage />} />
                  <Route path={routesConfig.mainRequests} element={<RequestsPage />} />
                  <Route path={routesConfig.categoryOnboarding} element={<CategoryOnboardingPage />} />
                  <Route path={routesConfig.serviceOnboarding} element={<ServiceOnboardingPage />} />
                  <Route path={routesConfig.localizationOnboarding} element={<LocalizationOnboardingPage />} />
                  <Route path={routesConfig.subscriptionOnboarding} element={<SubscriptionOnboardingPage />} />
                  <Route path={routesConfig.documentsOnboarding} element={<DocumentsOnboardingPage />} />
                  <Route path={routesConfig.termsAndConditions} element={<TermsAndConditionsPage />} />
                  <Route path={routesConfig.successCompletedOnboarding} element={<SuccessOnboardingPage />} />
                  <Route path={routesConfig.myProposals} element={<ProposalsPage />} />
                  <Route path={routesConfig.subscriptionProfessionalPage} element={<SubscriptionOnboardingPage />} />
                  <Route path={routesConfig.businessOnboarding} element={<BusinessOnboardingPage />} />
                  <Route path={routesConfig.termsAndConditionsPage} element={<ViewTermsAndConditionsPage />} />
                </Routes>
              </RoleGuard>
            }
          />

          {/* Admin Routes */}
          {/* <Route
            element={
              <RoleGuard allowedRoles={['ADMIN']}>
                <Routes>
                  // admin
                  <Route path={routesConfig.AdminPage} element={<AdminPage />} />
                  <Route path={routesConfig.AdminPageUser} element={<AdminPageUser />} />
                  <Route path={routesConfig.AdminPageEmpresas} element={<AdminPageEmpresas />} />
                  <Route path={routesConfig.AdminPageSolicitudes} element={<AdminPageSolicitudes />} />
                  <Route path={routesConfig.AdminPageOpiniones} element={<AdminPageOpiniones />} />
                  <Route path={routesConfig.AdminPageMarketPlace} element={<AdminPageMarket />} />
                  <Route path={routesConfig.AdminPageHistorial} element={<AdminPagePagos />} />
                  <Route path={routesConfig.AdminPagePagosGanancias} element={<AdminPagePagosGanancias />} />
                  <Route path={routesConfig.AdminPagePagospendientes} element={<AdminPagePagosPendientes />} />
                  <Route path={routesConfig.AdminPageEstadisticas} element={<AdminPageEstadisiticas />} />
                  <Route path={routesConfig.AdminDetailCompanyPage} element={<AdminDetailCompanyPage />} />
                  <Route path={routesConfig.AdminRequestClose} element={<AdminRequestClose />} />
                  <Route path={routesConfig.AdminRequestResume} element={<AdminRequestResume />} />
                  <Route path={routesConfig.AdminPageOpinionesHistorial} element={<AdminPageOpinionesHistorial />} />
                  <Route path={routesConfig.AdminPageReelsHistorial} element={<AdminPageReelsHistorial />} />
                </Routes>
              </RoleGuard>
            }
          /> */}

        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
