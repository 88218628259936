import React, { useEffect, useState } from "react";

import useServiceStore from "../store/useServiceStore";
import { useNavigate } from "react-router-dom";
import { Service } from "../types/service.types";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/outline";
import routesConfig from '../config/routes.config';
import { useTranslation } from "react-i18next";
import { ServiceCard } from "./ServiceCard";



const getMockServices = async () => {
  return {
    data: [
      { serviceId: "1", name: "Service 1", empresas: 5, numservices: 10, subcategoryId: "sub1", subcategoryName: "Subcategory 1" },
      { serviceId: "2", name: "Service 2", empresas: 3, numservices: 7, subcategoryId: "sub2", subcategoryName: "Subcategory 2" },
      { serviceId: "3", name: "Service 3", empresas: 8, numservices: 15, subcategoryId: "sub3", subcategoryName: "Subcategory 3" },
      { serviceId: "1", name: "Service 1", empresas: 5, numservices: 10, subcategoryId: "cat1", subcategoryName: "Category 1" },
      { serviceId: "2", name: "Service 2", empresas: 3, numservices: 7, subcategoryId: "cat2", subcategoryName: "Category 2" },
      { serviceId: "3", name: "Service 3", empresas: 8, numservices: 15, subcategoryId: "cat3", subcategoryName: "Category 3" },
    ],
  };
};

const ServiceSearchComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const { services, isLoading, error, fetchServices } = useServiceStore();

  /* useEffect(() => {
    fetchServices();
  }, [fetchServices]); */

  useEffect(() => {
    const fetchMockServices = async () => {
      try {
        const mockServices = await getMockServices();
        useServiceStore.setState({ services: mockServices, isLoading: false });
      } catch (error) {
        useServiceStore.setState({ error: "Error fetching mock services", isLoading: false });
      }
    };

    fetchMockServices();
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchClick = () => {
    console.log("Buscar:", searchTerm);
  };

  const handleRequestClick = (service: Service) => {
    console.log("Enviar Solicitud para:", service.name);
    navigate(routesConfig.serviceForm.replace(':id', service.serviceId));
  }

  const filteredServices = services?.data.filter((service: Service) =>
    service.name.toLowerCase().includes(searchTerm.toLowerCase())) || [];

  return (
    <div>
      <div className="text-center mt-4">
        <div className="relative max-w-md mx-auto">
          <input
            type="text"
            placeholder={t('landingPage.serviceSearch.searchPlaceholder')}
            value={searchTerm}
            onChange={handleSearchChange}
            className="px-4 py-2 border rounded-full w-full dark:bg-dark-cBackground dark:text-dark-cText pr-10 focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
          />
          <button
            onClick={handleSearchClick}
            className="absolute right-3 top-1/2 transform -translate-y-1/2 size-8 cursor-pointer"
          >
            <MagnifyingGlassCircleIcon className="fill-bgBrandLight stroke-bgBrand dark:fill-transparent" />
          </button>
        </div>
      </div>

      {/* {isLoading && <p className="text-center mt-4">Cargando servicios...</p>}
      {error && <p className="text-center mt-4 text-red-500">{error}</p>} */}

      {searchTerm && filteredServices.length > 0 && (
          <section className="my-6">
          <h2 className="text-3xl font-bold mb-6">{t('landingPage.serviceSearch.searchResults')}</h2>
          <div className="flex overflow-x-auto space-x-4 pb-2 [&::-webkit-scrollbar]:w-2
                                                          [&::-webkit-scrollbar]:h-2
                                                          [&::-webkit-scrollbar]:rounded-full
                                                          [&::-webkit-scrollbar]:bg-gray-100
                                                          [&::-webkit-scrollbar-thumb]:rounded-full
                                                          [&::-webkit-scrollbar-thumb]:bg-gray-300
                                                          [&::-webkit-scrollbar-track]:rounded-full
                                                          [&::-webkit-scrollbar-track]:bg-gray-100
                                                          dark:[&::-webkit-scrollbar-track]:bg-dark-cBackgroundSecondary
                                                          dark:[&::-webkit-scrollbar-thumb]:bg-dark-cTextSecondary">
            {filteredServices.map((service, index) => (
              <div key={index} className="flex-none w-80">
                <ServiceCard key={index} service={service} onRequestClick={handleRequestClick} />
              </div>
            ))}
          </div>
        </section>
      )}
    </div>
  );
};

export default ServiceSearchComponent;
