import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Para la navegación
import useCategoryStore from '../store/useCategoryStore'; // Store de categorías
import useOnboardingStore from '../store/useOnboardingStore'; // Store para la operación submitCategories
import Modal from '../components/ModalErrorValidation'; // Componente modal reutilizable
import Notification from '../components/Notification'; // Componente de notificaciones
import routesConfig from '../config/routes.config';
import { Category } from '../types/category.types';

const CategorySelectionPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { categories, searchCategories, fetchCategories } = useCategoryStore();
  const { submitCategories, isLoading } = useOnboardingStore(); // Usamos el store del onboarding para guardar categorías
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredResults, setFilteredResults] = useState<any[]>([]); // Resultados filtrados de la búsqueda
  const [showModal, setShowModal] = useState(false);
  const [notification, setNotification] = useState<{ message: string; type: 'success' | 'error' } | null>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null); // Para manejar clics fuera del autocomplete

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  // Cerrar autocomplete cuando se hace clic fuera
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
        setFilteredResults([]);
      }
    }

    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        setFilteredResults([]); // Cerrar autocomplete al presionar "Escape"
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [searchContainerRef]);

  const handleCategoryClick = (categoryId: string) => {
    if (selectedCategories.includes(categoryId)) {
      setSelectedCategories((prev) => prev.filter(id => id !== categoryId));
    } else if (selectedCategories.length < 2) {
      setSelectedCategories((prev) => [...prev, categoryId]);
    } else {
      setShowModal(true); // Mostrar modal si hay más de 2 categorías seleccionadas
    }
  };

  const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.length > 0) {
      const results = await searchCategories(query);

      if (Array.isArray(results)) {
        // Filtramos los resultados para no mostrar las categorías ya seleccionadas
        const filtered = results.filter(
          (result) => !selectedCategories.includes(result.categoryId)
        );
        setFilteredResults(filtered); // Si es un array, lo asignas
      } else {
        setFilteredResults([]); // En caso de que no sea un array, reseteas el estado
      }
    } else {
      setFilteredResults([]); // Limpia los resultados si no hay búsqueda
    }
  };

  const handleSubmit = async () => {
    if (selectedCategories.length === 2) {
      try {
        await submitCategories(selectedCategories);
        setNotification({ message: t('categorySelection.submitSuccess'), type: 'success' });
        navigate(routesConfig.serviceOnboarding); // Navegar a la ruta de onboarding de servicio
      } catch (error) {
        setNotification({ message: t('categorySelection.submitError'), type: 'error' });
      }
    }
  };

  const handleAutocompleteSelect = (categoryId: string, categoryName: string) => {
    if (!selectedCategories.includes(categoryId) && selectedCategories.length < 2) {
      setSelectedCategories((prev) => [...prev, categoryId]);
      setSearchQuery(''); // Limpia el campo de búsqueda después de seleccionar
      setFilteredResults([]); // Limpia los resultados filtrados
    } else {
      setShowModal(true); // Mostrar modal si hay más de 2 categorías seleccionadas
    }
  };

  return (
    <div className="min-h-screen p-8 bg-white font-poppins">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-xl font-bold">{t('categorySelection.step')} 1</h1>
      </div>

      {/* Título de Bienvenida */}
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold">
          {t('categorySelection.title')}
        </h2>
      </div>

      {/* Search bar con autocomplete */}
      <div className="relative flex justify-center mb-8" ref={searchContainerRef}>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder={t('categorySelection.searchPlaceholder')}
          className="w-full max-w-2xl px-4 py-2 border border-gray-300 rounded-full"
        />

        {/* Resultados del autocomplete */}
        {filteredResults.length > 0 && (
          <div className="absolute top-full mt-1 w-full max-w-2xl bg-white shadow-lg border border-gray-200 rounded-lg z-10">
            {filteredResults.map((result) => (
              <div
                key={result.categoryId}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => handleAutocompleteSelect(result.categoryId, result.name)}
              >
                {result.serviceName} - {result.categoryName}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 gap-8">
        {/* Lista de categorías con scroll interno */}
        <div className="w-full">
          <h3 className="font-bold mb-4">{t('categorySelection.chooseCategory')}</h3>
          <div
            className="grid grid-cols-2 gap-6 border border-gray-200 p-4 rounded-lg"
            style={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}
          >
            {categories?.data?.map((category: Category) => (
              <label
                key={category.categoryId}
                className={`flex items-center space-x-2 p-2 rounded-lg ${
                  selectedCategories.includes(category.categoryId)
                    ? 'bg-[#FFF3F3] border-[#F97068] text-black'
                    : 'text-black' // Ajusta el texto a negro cuando se selecciona
                }`} // Estilos condicionales para el color de fondo y texto cuando se selecciona
              >
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(category.categoryId)}
                  onChange={() => handleCategoryClick(category.categoryId)}
                  className="mr-2 accent-[#F97068] bg-white" // Color del checkbox ajustado a #F97068 y fondo blanco
                />
                <span>{category.name}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Categorías seleccionadas */}
        <div>
          <h3 className="font-bold mb-4">{t('categorySelection.selectedCategories')}</h3>
          <div className="space-y-4">
            {selectedCategories.length === 0 ? (
              <p>{t('categorySelection.noCategoriesSelected')}</p>
            ) : (
              selectedCategories.map((categoryId) => {
                const category = categories?.data.find((c: any) => c.categoryId === categoryId);
                return (
                  <div key={categoryId} className="flex items-center bg-gray-100 p-4 rounded-lg shadow-lg">
                    <img
                      src="https://via.placeholder.com/50"
                      alt={category?.name}
                      className="w-12 h-12 rounded-full mr-4"
                    />
                    <span className="font-bold">{category?.name}</span>
                  </div>
                );
              })
            )}
          </div>

          {/* Botón Continuar */}
          <div className="flex justify-center mt-8">
            <button
              onClick={handleSubmit}
              className="bg-red-500 text-white px-8 py-3 rounded-lg disabled:opacity-50"
              disabled={selectedCategories.length < 2 || isLoading}
            >
              {isLoading ? t('categorySelection.loading') : t('categorySelection.continue')}
            </button>
          </div>
        </div>
      </div>

      {/* Modal de validación si selecciona más de dos categorías */}
      {showModal && (
        <Modal
          title={t('categorySelection.errorValidationSelectionTitle')}
          message={t('categorySelection.errorValidationSelectionMessage')}
          buttonText={t('categorySelection.continue')}
          imageUrl="/assets/img/oops.png"
          onClose={() => setShowModal(false)}
          className="z-50"
        />
      )}

      {/* Notificación */}
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotification(null)}
        />
      )}
    </div>
  );
};

export default CategorySelectionPage;
