import React from "react";
import Sidebar from "./components/sidebar_admin";
import TableActiveRequest from "./components/table_solicitudes_activas";
import CardAdminXervix from "./components/card_admin";
import { Link } from "react-router-dom";

const AdminPageSolicitudes: React.FC = () => {
  return (
    <div className="flex p-6 bg-gray-50 min-h-screen">
    <Sidebar />
<div className="ml-10 w-full"> 
  <div className="flex justify-end text-sm mt-10">    
      <Link to="/admin/solicitudes" className="px-4"><span>Solicitudes</span></Link>
      <Link to="/admin/request/close" className="px-4"><span>Servicios</span></Link>
      <Link to="/admin/request/resume" className="px-4"><span>Resumen</span></Link>
    </div> 

    <div className="mt-8 grid grid-cols-1 gap-4 mb-8">
        <div className="px-4 py-8 bg-pink-100 text-center rounded-lg shadow">
          <h3 className="text-lg font-semibold">Solicitudes activas totales</h3>
          <p className="text-xl font-bold">2651</p>
        </div>
              
      </div>  

     <TableActiveRequest />
  </div>
</div>   
  );
};

export default AdminPageSolicitudes;