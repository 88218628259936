import React from 'react';
import CategoriesSlider from '../components/CategoriesSlider';
import { BaseLayout } from '../components/layout/BaseLayout';
import { useTranslation } from 'react-i18next';


const CategoriesPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <BaseLayout>
    <div className="flex flex-col items-center pb-6 px-4">
      <h1 className="text-2xl font-semibold pb-2 xl:text-3xl xl:font-bold xl:mb-6">{t("categoriesPage.title")}</h1>
      <CategoriesSlider isDashboard={false} />
    </div>
    </BaseLayout>
  );
};

export default CategoriesPage;