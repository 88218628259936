import { create } from 'zustand';

interface ThemeState {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}

const getInitialTheme = (): boolean => {
  const savedTheme = localStorage.getItem('isDarkMode');
  return savedTheme ? JSON.parse(savedTheme) : false;
};

export const useThemeStore = create<ThemeState>((set) => ({
  isDarkMode: getInitialTheme(),
  toggleDarkMode: () => set((state) => {
    const newTheme = !state.isDarkMode;
    localStorage.setItem('isDarkMode', JSON.stringify(newTheme));
    return { isDarkMode: newTheme };
  }),
}));