import React from "react";
import { useTranslation } from "react-i18next";
import { Service } from "@/types/service.types";
import { useNavigate } from "react-router-dom";
import routesConfig from "../config/routes.config";

interface ServiceCardProps {
  service: Service;
  onRequestClick: (service: Service) => void;
}

export const ServiceCard: React.FC<ServiceCardProps> = ({
  service,
  onRequestClick,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRequestClick = (service: Service) => { // Change 'any' to 'Service' in the future
    console.log("Enviar Solicitud para:", service.name);
    navigate(routesConfig.serviceForm.replace(":id", service.serviceId));
  };

  return (
    <div className="dark:bg-dark-cBackgroundSecondary rounded-lg shadow-md p-6">
      <h3 className="text-lg font-semibold mb-2">{service.name}</h3>
      <p className="text-sm font-semibold mb-2">
        {t("landingPage.topServicesHero.numberCompanies")}: 0
      </p> {/* {service.empresas} */}
      <p className="text-sm text-cTextSecondary dark:text-dark-cTextSecondary">
        {t("landingPage.topServicesHero.servicesDone")}: 0
      </p> {/* {service.numservices} */}
      <button
        onClick={() => onRequestClick(service)}
        className="mt-4 bg-bgBrand text-textOnBrand hover:bg-bgBrandHover px-4 py-2 rounded-lg"
      >
        {t("landingPage.topServicesHero.sendRequest")}
      </button>
    </div>
  );
};
