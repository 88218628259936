import React, { useState } from "react";
import { Link } from "react-router-dom";

interface Company {
  
  name: string;
  category: string;
  city: string;
  services: number;
}

const companiesData: Company[] = [
  { name: "Innovatek Solutions", category: "Desarrollo", city: "Medellín", services: 25 },
  { name: "CodeVerse Labs", category: "Desarrollo", city: "Medellín", services: 542 },
  { name: "NexaSys", category: "Desarrollo", city: "Medellín", services: 12 },
  { name: "Vitalis Wellness", category: "Carpinteria", city: "Medellín", services: 2452 },
  { name: "Equilibrio Natural", category: "Salud", city: "Medellín", services: 54 },
  { name: "Academia Futuro", category: "Educación", city: "Medellín", services: 564 },
  { name: "Sazón Artesanal", category: "Educación", city: "Medellín", services: 457 },
  { name: "Fresh Bite Foods", category: "Educación", city: "Medellín", services: 89 },
  { name: "EduMind Pro", category: "Idiomas", city: "Bogotá", services: 200 },
  { name: "Sana Vida", category: "Salud", city: "Bogotá", services: 123 },
  { name: "Clinica la soma", category: "Salud", city: "Medellín", services: 54 },
  { name: "Clinica las vegas", category: "Salud", city: "Medellín", services: 564 },
  { name: "BioBalance Care", category: "Salud", city: "Medellín", services: 457 },
  { name: "Laboratorio Genes", category: "Salud", city: "Medellín", services: 89 },
  { name: "Codeverse mewta", category: "Desarrollo", city: "Bogotá", services: 200 },
  { name: "Maderas y Maderas", category: "Carpinteria", city: "Bogotá", services: 123 },
  // Agrega más datos para alcanzar las 20 filas
];

const categories = [
  "Salud", "Carpinteria", "Idiomas", "Mascotas", "Deportes",
  "Hogar", "Desarrollo", "Tatuajes", "Belleza", "Automotriz",
  "Arquitectura", "Cuidadores",
];

const cities = [
  "Medellín", "Bogotá", "Bucaramanga", "Cali", "Cartagena",
  "Barranquilla", "Santa Marta",
];

const TableEmpresas: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [sortOption, setSortOption] = useState<"name" | "services" | null>(null);

  const filteredData = companiesData
    .filter((company) => !selectedCategory || company.category === selectedCategory)
    .filter((company) => !selectedCity || company.city === selectedCity)
    .sort((a, b) => {
      if (!sortOption) return 0;
      if (sortOption === "name") return a.name.localeCompare(b.name);
      if (sortOption === "services") return b.services - a.services;
      return 0;
    });

  return (
    <div >
      <h1 className="font-bold mb-4 text-center">Listado maestro de empresas activas</h1>
      <div className="flex items-center gap-8 mb-4">
        <div>
          <label className="font-medium text-xs">Categoría</label>
          <select
            className="border rounded-md p-2 ml-2 text-xs"
            value={selectedCategory || ""}
            onChange={(e) => setSelectedCategory(e.target.value || null)}
          >
            <option value="">Todas</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>


        <div>
          <label className="font-medium text-xs">Ciudad</label>
          <select
            className="border rounded-md p-2 ml-2 text-xs "
            value={selectedCity || ""}
            onChange={(e) => setSelectedCity(e.target.value || null)}
          >
            <option value="">Todas</option>
            {cities.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>


        <div>
          <button
            className="text-red-500 hover:text-red-700 text-xs"
            onClick={() => {
              setSelectedCategory(null);
              setSelectedCity(null);
              setSortOption(null);
            }}
          >
            Reset Filtros
          </button>
        </div>


        <div>
          <label className="font-medium text-xs">Ordenar</label>
          <button
            className={`ml-2 ${sortOption === "name" ? "text-pink-500" : "text-gray-500"} text-xs`}
            onClick={() => setSortOption("name")}
          >
            Empresa
          </button>
          <button
            className={`ml-2 ${sortOption === "services" ? "text-pink-500" : "text-gray-500"} text-xs`}
            onClick={() => setSortOption("services")}
          >
            #Servicios
          </button>
        </div>


      </div>

      
      <div className="overflow-y-auto max-h-96 border rounded-md">
        <table className="table-auto w-full text-left">
          <thead className="bg-gray-100">
            <tr>            
              <th className="px-4 py-2 text-xs text-left">Nombre Empresa</th>
              <th className="px-4 py-2 text-xs text-center">Categoría</th>
              <th className="px-4 py-2 text-xs text-center">Ciudad</th>
              <th className="px-4 py-2 text-xs text-center">#Servicios</th>
              <th className="px-4 py-2 text-xs text-center">Acción</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.slice(0, 20).map((company, index) => (
              <tr key={index} className="border-t">              
                <td className="px-4 py-3 text-xs text-left text-gray-700">{company.name}</td>
                <td className="px-4 py-3 text-xs text-center text-gray-700">{company.category}</td>
                <td className="px-4 py-3 text-xs text-center text-gray-700">{company.city}</td>
                <td className="px-4 py-3 text-xs text-center text-gray-700">{company.services}</td>
                <td className="px-4 py-3 text-xs text-center text-gray-700">
                <Link to="/admin/empresas/idempresa" className="px-4 py-2 text-xs text-center">                
                <span className={`text-red-500 hover:text-red-700 text-xs text-left px-4 py-2`}>Abrir</span>
              </Link>
                </td>               
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableEmpresas;
