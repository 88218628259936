import { create } from 'zustand';
import { Service } from '../types/service.types';
import serviceService from '../services/service.service';
import { ServiceResponse, ServiceQuerySearchResponse } from '../types/service.types';

interface ServiceStoreState {
  services: ServiceResponse | null;
  searchResults: ServiceQuerySearchResponse[];
  isLoading: boolean;
  error: string | null;
  fetchServices: () => Promise<void>;
  filterServicesByCategory: (categoryName: string) => any[];
}

const useServiceStore = create<ServiceStoreState>((set, get) => ({
  services: null,
  searchResults: [],
  isLoading: false,
  error: null,

  fetchServices: async () => {
    set({ isLoading: true });
    try {
      const services = await serviceService.fetchServices();
      set({ services, isLoading: false });
    } catch (error: unknown) {
      if (error instanceof Error) {
        set({ error: error.message, isLoading: false });
      } else {
        set({ error: 'An unknown error occurred', isLoading: false });
      }
    }
  },

  filterServicesByCategory: (categoryName: string) => {
    return (get().services?.data || []).filter((service: Service) => service.subcategoryName === categoryName);
  },
}));

export default useServiceStore;